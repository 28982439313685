// import { useMutation } from '@apollo/client';
import TextField from '@mui/material/TextField';
import { useMe } from '@shared/contexts/hooks/useMe';
import { useUpdateUserSettingsMutation } from '@shared/generated/graphql';
import { useState } from 'react';
import { Button } from '../button';
import { Modal } from '../modal';
import { Text } from '../text';

export function ConfigureAutoResponse() {
  const { me } = useMe();
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  return (
    <>
      <Modal.Root open={open}>
        <Modal.Content className="p-2">
          <div className="flex justify-end">
            <Button className="" onPress={() => setOpen(false)} size="xs">
              x
            </Button>
          </div>
          <Text type="body-sm">Configure quote auto-response</Text>
          <Text type="body-xs">
            Hey Vooma! Please auto respond to quotes when:
          </Text>
          <TextField
            fullWidth
            margin="dense"
            multiline
            placeholder="(example) when rates are less than 1000 and equipment is flatbed"
            value={prompt}
            variant="outlined"
            minRows={4}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPrompt(event.target.value);
            }}
          />
          <div className="flex justify-end">
            <Button
              className=""
              onPress={() => {
                me?.id &&
                  void updateUserSettings({
                    variables: {
                      input: {
                        id: me?.id,
                        settings: { autoResponseInstructions: prompt },
                      },
                    },
                  });
                setOpen(false);
              }}
              size="xs"
              variant="primary"
            >
              Submit
            </Button>
          </div>
        </Modal.Content>
      </Modal.Root>
      <div onClick={() => setOpen(true)}>ConfigureAutoResponse</div>
    </>
  );
}
