import { JobsState } from '@shared/contexts/pluginContexts/EmailThreadContext';
import { FeedItemType, JobCardFeedItem } from '../types';

export const transformThreadJobs = (
  threadJobs: JobsState
): JobCardFeedItem[] => {
  return threadJobs.jobs.map((job) => {
    const jobType = threadJobs.jobTypes[job.id];
    return {
      type: FeedItemType.JOB_CARD,
      // TODO(parlato): We should use job.createdAtDisplay, but
      // that's not available on the backend
      createdAt: job.createdAt,
      // TODO(parlato): Pull the job owner
      //   createdBy: job.createdBy,
      data: {
        job,
        initialJobType: jobType,
      },
    };
  });
};
