import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

/** An object with standard fields for created and updated data */
export type AbstractEntity = {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ActionEntityIds = {
  __typename?: 'ActionEntityIds';
  externalMessageIds?: Maybe<Array<Scalars['ID']['output']>>;
  orderIds?: Maybe<Array<Scalars['ID']['output']>>;
  quoteIds?: Maybe<Array<Scalars['ID']['output']>>;
};

export type AddDocumentToJobInput = {
  jobId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AddDocumentToJobPayload = {
  __typename?: 'AddDocumentToJobPayload';
  success: Scalars['Boolean']['output'];
};

export type AddDocumentToOrderInput = {
  documentId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
};

export type AddDocumentToOrderPayload = {
  __typename?: 'AddDocumentToOrderPayload';
  success: Scalars['Boolean']['output'];
};

export type AddUserToOrganizationInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddUserToOrganizationPayload = {
  __typename?: 'AddUserToOrganizationPayload';
  success: Scalars['Boolean']['output'];
};

export type AgentAction = {
  __typename?: 'AgentAction';
  confidence: Scalars['Float']['output'];
  entityIds?: Maybe<ActionEntityIds>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  proposedAt: Scalars['DateTimeISO']['output'];
  type: AgentActionType;
};

export enum AgentActionType {
  ASK_FOR_HELP = 'ASK_FOR_HELP',
  BUILD_ORDER = 'BUILD_ORDER',
  BUILD_QUOTE = 'BUILD_QUOTE',
  MARK_QUOTE_LOST = 'MARK_QUOTE_LOST',
  MARK_QUOTE_QUOTED = 'MARK_QUOTE_QUOTED',
  MARK_QUOTE_WON = 'MARK_QUOTE_WON',
  REPEAT_ORDER = 'REPEAT_ORDER',
  REPLICATE_ORDER = 'REPLICATE_ORDER',
  SCHEDULE_ORDER = 'SCHEDULE_ORDER'
}

export type AnalyticsContext = {
  apiClient: VoomaApiClient;
  clientVersion?: InputMaybe<Scalars['String']['input']>;
  navigator?: InputMaybe<NavigatorFields>;
};

export type ApiResponse = AbstractEntity & Node & {
  __typename?: 'ApiResponse';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  method: RequestMethod;
  organization: Organization;
  queryParams?: Maybe<Scalars['String']['output']>;
  requestBody?: Maybe<Scalars['JSON']['output']>;
  responseBody?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url: Scalars['String']['output'];
};

export enum AuthPolicyLevel {
  ALLOW_SOFT_FAILURES = 'ALLOW_SOFT_FAILURES',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PASS_REQUIRED = 'PASS_REQUIRED'
}

export type AuthToken = AbstractEntity & Node & {
  __typename?: 'AuthToken';
  accessToken?: Maybe<Scalars['String']['output']>;
  /** Serialized as JSON */
  config?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  expiresAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  type: AuthTokenType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

/** The type of service to which the auth token grants access. */
export enum AuthTokenType {
  DAT = 'DAT',
  FRONT_CORE_API = 'FRONT_CORE_API',
  GMAIL_API = 'GMAIL_API',
  GREENSCREENS = 'GREENSCREENS',
  MICROSOFT_GRAPH_API = 'MICROSOFT_GRAPH_API',
  TURVO = 'TURVO'
}

export enum AutoSubmitScope {
  DISABLED = 'DISABLED',
  MULTIPLE_ORDERS = 'MULTIPLE_ORDERS',
  SINGLE_ORDER = 'SINGLE_ORDER'
}

export type BaseEvent = AbstractEntity & Node & {
  __typename?: 'BaseEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<User>;
};

export type BaseEventConnection = {
  __typename?: 'BaseEventConnection';
  count: Scalars['Int']['output'];
  edges: Array<BaseEventEdge>;
  nodes?: Maybe<Array<BaseEvent>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type BaseEventEdge = {
  __typename?: 'BaseEventEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: BaseEvent;
};

export type BaseEventsInput = {
  entityId?: InputMaybe<Scalars['String']['input']>;
};

export type BasicUser = {
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type BooleanFlagConfiguration = {
  __typename?: 'BooleanFlagConfiguration';
  all?: Maybe<Scalars['Boolean']['output']>;
  ids?: Maybe<Array<Scalars['String']['output']>>;
};

export type BulkCreatePlacesInput = {
  places: Array<CreatePlaceInput>;
};

export type BulkCreatePlacesPayload = {
  __typename?: 'BulkCreatePlacesPayload';
  places: Array<CreatePlacePayload>;
};

export type BulkDeleteJobsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteJobsPayload = {
  __typename?: 'BulkDeleteJobsPayload';
  success: Scalars['Boolean']['output'];
};

export type BulkDeleteOrdersInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteOrdersPayload = {
  __typename?: 'BulkDeleteOrdersPayload';
  success: Scalars['Boolean']['output'];
};

export type BulkDeleteOriginatorsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteOriginatorsPayload = {
  __typename?: 'BulkDeleteOriginatorsPayload';
  success: Scalars['Boolean']['output'];
};

export type BulkDeletePlacesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeletePlacesPayload = {
  __typename?: 'BulkDeletePlacesPayload';
  success: Scalars['Boolean']['output'];
};

export type BulkDeleteQuotesInput = {
  ids: Array<Scalars['ID']['input']>;
};

export type BulkDeleteQuotesPayload = {
  __typename?: 'BulkDeleteQuotesPayload';
  success: Scalars['Boolean']['output'];
};

/** The type of api request being cached. */
export enum CachedRequestType {
  GREENSCREENS_INDICES = 'GREENSCREENS_INDICES',
  LOAD_TO_TRUCK_RATIOS = 'LOAD_TO_TRUCK_RATIOS',
  MARKET_CONDITIONS_INDICES = 'MARKET_CONDITIONS_INDICES'
}

export type CarrierRate = AbstractEntity & Node & {
  __typename?: 'CarrierRate';
  /** Serialized as JSON */
  computedRate?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  /** Serialized as JSON */
  loadData?: Maybe<Scalars['JSON']['output']>;
  /** Serialized as JSON */
  metadata?: Maybe<Scalars['JSON']['output']>;
  quote: Quote;
  ratingMethod: RatingMethod;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

/** The status of the classification case. */
export enum ClassificationStatus {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  PENDING_CLASSIFICATION = 'PENDING_CLASSIFICATION',
  SKIPPED = 'SKIPPED',
  SUCCEEDED = 'SUCCEEDED'
}

export type CommodityOption = {
  __typename?: 'CommodityOption';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ComputeRatesInput = {
  id: Scalars['ID']['input'];
};

export type ComputeRatesPayload = {
  __typename?: 'ComputeRatesPayload';
  carrierRates: Array<CarrierRate>;
  customerRates: Array<CustomerRate>;
  ratingErrors?: Maybe<Array<RatingError>>;
  success: Scalars['Boolean']['output'];
};

export enum ComputerVisionModelName {
  AZURE = 'AZURE',
  AZURE_PLUS_LLM = 'AZURE_PLUS_LLM',
  GPT_4_VISION = 'GPT_4_VISION'
}

export type ConfidenceRange = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type ConfigurableOptions = {
  __typename?: 'ConfigurableOptions';
  commodities?: Maybe<Array<CommodityOption>>;
  freightOrderIntegrationType?: Maybe<Scalars['String']['output']>;
  missingLocationCodeCreatesLocation?: Maybe<Scalars['Boolean']['output']>;
};

export enum ConfiguredNotesPolicy {
  CONCATENATE = 'CONCATENATE',
  REPLACE = 'REPLACE'
}

export type Contact = AbstractEntity & Node & {
  __typename?: 'Contact';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  originator?: Maybe<Originator>;
  phone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContactEdge>;
  nodes?: Maybe<Array<Contact>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ContactEdge = {
  __typename?: 'ContactEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Contact;
};

export type ContactsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Conversation = AbstractEntity & Node & {
  __typename?: 'Conversation';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalIdentifier: Scalars['String']['output'];
  externalMessages: Array<ExternalMessage>;
  id: Scalars['ID']['output'];
  organization: Organization;
  purpose?: Maybe<AgentAction>;
  status: ConversationStatus;
  type: ConversationType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export type ConversationExternalMessagesInput = {
  apiClient: VoomaApiClient;
  externalIdentifiers: Array<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ConversationStatus {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING'
}

export enum ConversationType {
  PHONE_CALL = 'PHONE_CALL'
}

export type CopyJobToComplementaryInput = {
  id: Scalars['ID']['input'];
};

export type CopyJobToComplementaryPayload = {
  __typename?: 'CopyJobToComplementaryPayload';
  complementaryJob: Job;
  success: Scalars['Boolean']['output'];
};

export type CreateAuthTokenInput = {
  email: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  type: AuthTokenType;
  userId: Scalars['String']['input'];
};

export type CreateAuthTokenPayload = {
  __typename?: 'CreateAuthTokenPayload';
  email?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateBaseEventInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateBaseEventPayload = {
  __typename?: 'CreateBaseEventPayload';
  event: BaseEvent;
  success: Scalars['Boolean']['output'];
};

export type CreateCarrierRateInput = {
  computedRate?: InputMaybe<Scalars['JSON']['input']>;
  loadData?: InputMaybe<Scalars['JSON']['input']>;
  quoteId: Scalars['String']['input'];
  ratingMethod: RatingMethod;
};

export type CreateCarrierRatePayload = {
  __typename?: 'CreateCarrierRatePayload';
  carrierRate: CarrierRate;
  success: Scalars['Boolean']['output'];
};

export type CreateContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContactPayload = {
  __typename?: 'CreateContactPayload';
  /** The contact that was filed */
  contact: Contact;
  /** Whether or not the contact was created successfully */
  success: Scalars['Boolean']['output'];
};

export type CreateCustomerRateInput = {
  loadData?: InputMaybe<Scalars['JSON']['input']>;
  quoteId: Scalars['String']['input'];
  rate?: InputMaybe<Scalars['JSON']['input']>;
  type: CustomerRateType;
};

export type CreateCustomerRatePayload = {
  __typename?: 'CreateCustomerRatePayload';
  customerRate: CustomerRate;
  success: Scalars['Boolean']['output'];
};

export enum CreateExternalIdMappingFailureReason {
  EXTERNAL_ID_ALREADY_EXISTS = 'EXTERNAL_ID_ALREADY_EXISTS',
  INVALID_INTEGRATION_TYPE = 'INVALID_INTEGRATION_TYPE',
  SINGLE_ENTITY_ID_NEEDED = 'SINGLE_ENTITY_ID_NEEDED',
  UNKNOWN = 'UNKNOWN'
}

export type CreateExternalIdMappingInput = {
  externalId: Scalars['String']['input'];
  integrationType: Scalars['String']['input'];
  orderId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  originatorId?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateExternalIdMappingPayload = {
  __typename?: 'CreateExternalIdMappingPayload';
  errors?: Maybe<Array<CreateExternalIdMappingFailureReason>>;
  externalIdMapping?: Maybe<ExternalIdMapping>;
  success: Scalars['Boolean']['output'];
};

export type CreateExternalMessageJobInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  externalMessageId: Scalars['ID']['input'];
  isOptimistic?: InputMaybe<Scalars['Boolean']['input']>;
  jobSource?: InputMaybe<JobSource>;
  /** The type of job to create */
  jobType: JobType;
};

export type CreateFrontJobInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  emailMetadata?: InputMaybe<Scalars['JSON']['input']>;
  frontMessageData: FrontMessageData;
  isOptimistic?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreationPolicy?: InputMaybe<JobCreationPolicy>;
  jobSource?: InputMaybe<JobSource>;
  /** The type of job to create */
  jobType: JobType;
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFrontReplyAllInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  conversationId: Scalars['String']['input'];
  frontUserEmail: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateFrontReplyAllPayload = {
  __typename?: 'CreateFrontReplyAllPayload';
  messageId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreateGmailJobInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  emailMetadata?: InputMaybe<Scalars['JSON']['input']>;
  gmailMessageData: GmailMessageData;
  isOptimistic?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreationPolicy?: InputMaybe<JobCreationPolicy>;
  jobSource?: InputMaybe<JobSource>;
  /** The type of job to create */
  jobType: JobType;
  organizationId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum CreateJobEventFailureReason {
  JOB_DELETED = 'JOB_DELETED'
}

export type CreateJobEventInput = {
  jobId: Scalars['String']['input'];
  type: JobEventType;
};

export type CreateJobEventPayload = {
  __typename?: 'CreateJobEventPayload';
  errors?: Maybe<Array<CreateJobEventFailureReason>>;
  jobEvent?: Maybe<JobEvent>;
  success: Scalars['Boolean']['output'];
};

export type CreateJobInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  emailMetadata?: InputMaybe<Scalars['JSON']['input']>;
  isOptimistic?: InputMaybe<Scalars['Boolean']['input']>;
  jobSource?: InputMaybe<JobSource>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  uploadIds?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateJobPayload = {
  __typename?: 'CreateJobPayload';
  job: Job;
  resultingAction: JobCreationResultingAction;
  success: Scalars['Boolean']['output'];
};

export type CreateLabeledComputationInput = {
  labeledComputationData: LabeledComputationDataInput;
  savedComputationId: Scalars['String']['input'];
  source: LabeledComputationSource;
  type: LabeledComputationType;
};

export type CreateLabeledComputationPayload = {
  __typename?: 'CreateLabeledComputationPayload';
  labeledComputation: LabeledComputation;
  success: Scalars['Boolean']['output'];
};

export enum CreateMemoryFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type CreateMemoryInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  labeledComputationId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateMemoryPayload = {
  __typename?: 'CreateMemoryPayload';
  errors?: Maybe<Array<CreateMemoryFailureReason>>;
  memory?: Maybe<Memory>;
  success: Scalars['Boolean']['output'];
};

export type CreateMessageQuoteAssociationInput = {
  entityId: Scalars['ID']['input'];
  externalMessageId: Scalars['ID']['input'];
  scores?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateMessageQuoteAssociationPayload = {
  __typename?: 'CreateMessageQuoteAssociationPayload';
  messageQuoteAssociation: MessageQuoteAssociation;
  success: Scalars['Boolean']['output'];
};

export type CreateOrUpdateIntegrationCredentialsInput = {
  /** Serialized as JSON */
  config?: InputMaybe<Scalars['JSON']['input']>;
  organizationId: Scalars['ID']['input'];
  type: IntegrationCredentialsType;
  usageIndicator: UsageIndicator;
};

export type CreateOrUpdateIntegrationCredentialsPayload = {
  __typename?: 'CreateOrUpdateIntegrationCredentialsPayload';
  success: Scalars['Boolean']['output'];
};

export enum CreateOrderEventFailureReason {
  ORDER_DELETED = 'ORDER_DELETED'
}

export type CreateOrderEventInput = {
  /** Serialized as JSON */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  orderId: Scalars['String']['input'];
  type: OrderEventType;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderEventPayload = {
  __typename?: 'CreateOrderEventPayload';
  errors?: Maybe<Array<CreateOrderEventFailureReason>>;
  orderEvent?: Maybe<OrderEvent>;
  success: Scalars['Boolean']['output'];
};

export type CreateOrderInput = {
  contactId?: InputMaybe<Scalars['ID']['input']>;
  extractedData?: InputMaybe<Scalars['String']['input']>;
  jobId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload';
  order: Order;
  success: Scalars['Boolean']['output'];
};

export type CreateOrganizationInput = {
  name: Scalars['String']['input'];
};

export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  organization: Organization;
  shadowOrganization: Organization;
  success: Scalars['Boolean']['output'];
};

export type CreateOrganizationUserRelationInput = {
  organizationId: Scalars['String']['input'];
  roles?: InputMaybe<Array<OrganizationUserRole>>;
  userId: Scalars['String']['input'];
};

export type CreateOrganizationUserRelationPayload = {
  __typename?: 'CreateOrganizationUserRelationPayload';
  organizationUserRelation: OrganizationUserRelation;
  success: Scalars['Boolean']['output'];
};

export type CreateOriginatorEventInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  name: OriginatorEventType;
  originatorId: Scalars['String']['input'];
};

export type CreateOriginatorEventPayload = {
  __typename?: 'CreateOriginatorEventPayload';
  event: OriginatorEvent;
  success: Scalars['Boolean']['output'];
};

export type CreateOriginatorInput = {
  autoSubmitScope?: InputMaybe<AutoSubmitScope>;
  configOverrides?: InputMaybe<Scalars['JSON']['input']>;
  expectedWeeklyOrderCount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  orderNotesConfig?: InputMaybe<NotesFieldConfigInput>;
  organizationId: Scalars['ID']['input'];
  quoteAutoReplyScope?: InputMaybe<QuoteAutoReplyScope>;
  status?: InputMaybe<OriginatorStatus>;
  transmitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  voomaSupportNotes?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOriginatorPayload = {
  __typename?: 'CreateOriginatorPayload';
  originator: Originator;
  success: Scalars['Boolean']['output'];
};

export type CreateOutlookJobInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  emailMetadata?: InputMaybe<Scalars['JSON']['input']>;
  isOptimistic?: InputMaybe<Scalars['Boolean']['input']>;
  isSharedInbox?: InputMaybe<Scalars['Boolean']['input']>;
  jobCreationPolicy?: InputMaybe<JobCreationPolicy>;
  jobSource?: InputMaybe<JobSource>;
  /** The type of job to create */
  jobType: JobType;
  organizationId: Scalars['ID']['input'];
  outlookMessageData: OutlookMessageData;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateOutlookReplyAllInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  messageId: Scalars['String']['input'];
  sharedInbox?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOutlookReplyAllPayload = {
  __typename?: 'CreateOutlookReplyAllPayload';
  messageId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type CreatePlaceInput = {
  address?: InputMaybe<LocationAddressInput>;
  aliases?: InputMaybe<Array<Scalars['String']['input']>>;
  creationProvenance?: InputMaybe<PlaceCreationProvenance>;
  locationCode?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<PlaceTag>>;
};

/** Return type of createPlace mutation */
export type CreatePlacePayload = {
  __typename?: 'CreatePlacePayload';
  /** The reason the place creation failed, if applicable */
  failureReason?: Maybe<PlaceCreationFailureReason>;
  place: Place;
  /** Whether or not the place was created successfully */
  success: Scalars['Boolean']['output'];
};

export type CreateQuoteEventInput = {
  /** Serialized as JSON */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  quoteId: Scalars['String']['input'];
  type: QuoteEventType;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateQuoteEventPayload = {
  __typename?: 'CreateQuoteEventPayload';
  quoteEvent: QuoteEvent;
  success: Scalars['Boolean']['output'];
};

export type CreateQuoteInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  extractedData?: InputMaybe<Scalars['JSON']['input']>;
  jobId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateQuoteNoteInput = {
  note: Scalars['String']['input'];
  quoteId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateQuoteNotePayload = {
  __typename?: 'CreateQuoteNotePayload';
  quoteNote: QuoteNote;
  success: Scalars['Boolean']['output'];
};

export type CreateQuotePayload = {
  __typename?: 'CreateQuotePayload';
  quote: Quote;
  success: Scalars['Boolean']['output'];
};

export type CreateQuotesReportPayload = {
  __typename?: 'CreateQuotesReportPayload';
  success: Scalars['Boolean']['output'];
};

export type CreateSavedComputationInput = {
  dataUrl: Scalars['String']['input'];
  externalMessageId?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  originatorId?: InputMaybe<Scalars['String']['input']>;
  output?: InputMaybe<Scalars['JSON']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  type: SavedComputationType;
};

export type CreateSavedComputationPayload = {
  __typename?: 'CreateSavedComputationPayload';
  savedComputation: SavedComputation;
  success: Scalars['Boolean']['output'];
};

export type CreateTemplateInput = {
  initializeFromDefaultTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  specializedTemplate?: InputMaybe<Scalars['String']['input']>;
  type: TemplateType;
};

export type CreateTemplatePayload = {
  __typename?: 'CreateTemplatePayload';
  errors?: Maybe<Array<TemplateCreationFailureReason>>;
  success: Scalars['Boolean']['output'];
  template: Template;
};

export enum CreateTransformationConfigFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type CreateTransformationConfigInput = {
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  params: Scalars['JSON']['input'];
  transformationDefinitionId: Scalars['ID']['input'];
};

export type CreateTransformationConfigPayload = {
  __typename?: 'CreateTransformationConfigPayload';
  errors?: Maybe<Array<CreateTransformationConfigFailureReason>>;
  success: Scalars['Boolean']['output'];
  transformationConfig?: Maybe<TransformationConfig>;
};

export enum CreateTransformationDefinitionFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type CreateTransformationDefinitionInput = {
  definition: Scalars['JSON']['input'];
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateTransformationDefinitionPayload = {
  __typename?: 'CreateTransformationDefinitionPayload';
  errors?: Maybe<Array<CreateTransformationDefinitionFailureReason>>;
  success: Scalars['Boolean']['output'];
  transformationDefinition?: Maybe<TransformationDefinition>;
};

export enum CreateWorkflowFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type CreateWorkflowInput = {
  organizationId: Scalars['String']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  transformationConfigIds: Array<Scalars['ID']['input']>;
  trigger: FlowTriggerInput;
};

export type CreateWorkflowPayload = {
  __typename?: 'CreateWorkflowPayload';
  errors?: Maybe<Array<CreateWorkflowFailureReason>>;
  success: Scalars['Boolean']['output'];
  workflow?: Maybe<Workflow>;
};

export enum CreatedLocationOwnershipPolicy {
  ORGANIZATION = 'ORGANIZATION',
  ORIGINATOR = 'ORIGINATOR'
}

export type CustomerRate = AbstractEntity & Node & {
  __typename?: 'CustomerRate';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  /** Serialized as JSON */
  loadData?: Maybe<Scalars['JSON']['output']>;
  /** Serialized as JSON */
  metadata?: Maybe<Scalars['JSON']['output']>;
  quote: Quote;
  /** Serialized as JSON */
  rate?: Maybe<Scalars['JSON']['output']>;
  type: CustomerRateType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

/** The type of customer rate */
export enum CustomerRateType {
  BOOKED = 'BOOKED',
  NEGOTIATED = 'NEGOTIATED',
  QUOTED = 'QUOTED'
}

export type DeleteCarrierRateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCarrierRatePayload = {
  __typename?: 'DeleteCarrierRatePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteCustomerRateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCustomerRatePayload = {
  __typename?: 'DeleteCustomerRatePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteJobDocumentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteJobDocumentPayload = {
  __typename?: 'DeleteJobDocumentPayload';
  /** Whether or not the document was deleted successfully */
  success: Scalars['Boolean']['output'];
};

export type DeleteJobInput = {
  id: Scalars['ID']['input'];
};

export type DeleteJobPayload = {
  __typename?: 'DeleteJobPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteLabeledComputationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteLabeledComputationPayload = {
  __typename?: 'DeleteLabeledComputationPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteMemoryInput = {
  id: Scalars['ID']['input'];
};

export type DeleteMemoryPayload = {
  __typename?: 'DeleteMemoryPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteOrderInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOrderPayload = {
  __typename?: 'DeleteOrderPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteOriginatorInput = {
  id: Scalars['ID']['input'];
};

export type DeleteOriginatorPayload = {
  __typename?: 'DeleteOriginatorPayload';
  success: Scalars['Boolean']['output'];
};

export type DeletePlaceInput = {
  id: Scalars['ID']['input'];
};

export type DeletePlacePayload = {
  __typename?: 'DeletePlacePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteQuoteInput = {
  id: Scalars['ID']['input'];
};

export type DeleteQuotePayload = {
  __typename?: 'DeleteQuotePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteTemplateInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTemplatePayload = {
  __typename?: 'DeleteTemplatePayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteTransformationConfigInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTransformationConfigPayload = {
  __typename?: 'DeleteTransformationConfigPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteTransformationDefinitionInput = {
  id: Scalars['ID']['input'];
};

export type DeleteTransformationDefinitionPayload = {
  __typename?: 'DeleteTransformationDefinitionPayload';
  success: Scalars['Boolean']['output'];
};

export type DeleteWorkflowInput = {
  id: Scalars['ID']['input'];
};

export type DeleteWorkflowPayload = {
  __typename?: 'DeleteWorkflowPayload';
  success: Scalars['Boolean']['output'];
};

export enum DocumentInterpretationModelName {
  CLAUDE_2_0 = 'CLAUDE_2_0',
  CLAUDE_2_1 = 'CLAUDE_2_1',
  CLAUDE_3_5_SONNET = 'CLAUDE_3_5_SONNET',
  CLAUDE_3_OPUS = 'CLAUDE_3_OPUS',
  CLAUDE_3_SONNET = 'CLAUDE_3_SONNET',
  GPT_3_5_TURBO = 'GPT_3_5_TURBO',
  GPT_3_5_TURBO_16K = 'GPT_3_5_TURBO_16K',
  GPT_3_5_TURBO_INSTRUCT = 'GPT_3_5_TURBO_INSTRUCT',
  GPT_4 = 'GPT_4',
  GPT_4O = 'GPT_4O',
  GPT_4O_MINI = 'GPT_4O_MINI',
  GPT_4_32K = 'GPT_4_32K'
}

export type DocumentRevision = AbstractEntity & Node & {
  __typename?: 'DocumentRevision';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  entity: Template;
  id: Scalars['ID']['output'];
  /** Serialized as JSON */
  jsonData?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type DownloadArtifactsInput = {
  id: Scalars['ID']['input'];
};

export type DownloadArtifactsPayload = {
  __typename?: 'DownloadArtifactsPayload';
  downloadUrl: Scalars['String']['output'];
};

export type DownloadOrderInput = {
  id: Scalars['ID']['input'];
};

export type DownloadOrderPayload = {
  __typename?: 'DownloadOrderPayload';
  downloadUrl: Scalars['String']['output'];
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  filename: Scalars['String']['output'];
};

export type EmailAuthPolicy = {
  __typename?: 'EmailAuthPolicy';
  dkimPolicy?: Maybe<AuthPolicyLevel>;
  dmarcPolicy?: Maybe<AuthPolicyLevel>;
  spamPolicy?: Maybe<AuthPolicyLevel>;
  spfPolicy?: Maybe<AuthPolicyLevel>;
};

export type EmailAuthPolicyInput = {
  dkimPolicy?: InputMaybe<AuthPolicyLevel>;
  dmarcPolicy?: InputMaybe<AuthPolicyLevel>;
  spamPolicy?: InputMaybe<AuthPolicyLevel>;
  spfPolicy?: InputMaybe<AuthPolicyLevel>;
};

export type EmailHeaderMetadata = {
  __typename?: 'EmailHeaderMetadata';
  bcc?: Maybe<Scalars['String']['output']>;
  cc?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type EmailScanningSettings = {
  __typename?: 'EmailScanningSettings';
  /** Use contact list as allowed senders for inbox automations */
  allowContacts?: Maybe<Scalars['Boolean']['output']>;
  allowList: Array<Scalars['String']['output']>;
  blockList: Array<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
};

export type EmailScanningSettingsInput = {
  /** Use contact list as allowed senders for inbox automations */
  allowContacts?: InputMaybe<Scalars['Boolean']['input']>;
  allowList: Array<Scalars['String']['input']>;
  blockList: Array<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
};

export type ExternalIdMapping = AbstractEntity & Node & {
  __typename?: 'ExternalIdMapping';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  integrationType: Scalars['String']['output'];
  orderId?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  originatorId?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
  quoteId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ExternalMessage = AbstractEntity & Node & {
  __typename?: 'ExternalMessage';
  classifications?: Maybe<Scalars['JSON']['output']>;
  conversation?: Maybe<Conversation>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  /** Serialized as JSON */
  emailMetadata?: Maybe<Scalars['JSON']['output']>;
  entityAssociations: Array<MessageEntityAssociation>;
  externalIdentifier: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organization: Organization;
  s3Url?: Maybe<Scalars['String']['output']>;
  status: ClassificationStatus;
  type: ExternalMessageType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export type ExternalMessageInput = {
  id: Scalars['ID']['input'];
};

export type ExternalMessagePayload = {
  __typename?: 'ExternalMessagePayload';
  content: Scalars['String']['output'];
  externalMessage: ExternalMessage;
  parsedEmail?: Maybe<ParsedEmail>;
};

/** The type of case to be classified. */
export enum ExternalMessageType {
  GMAIL_MESSAGE = 'GMAIL_MESSAGE',
  HOSTED_MCLEOD_IMPORT = 'HOSTED_MCLEOD_IMPORT',
  MICROSOFT_MESSAGE = 'MICROSOFT_MESSAGE',
  TURVO_IMPORT = 'TURVO_IMPORT',
  VOICE_TRANSCRIPT = 'VOICE_TRANSCRIPT'
}

export type ExternalMessagesInput = {
  /** @deprecated Ignored by resolver */
  lookbackMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type FeatureFlag = AbstractEntity & Node & {
  __typename?: 'FeatureFlag';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  name: FeatureFlagName;
  organizations: BooleanFlagConfiguration;
  originators: BooleanFlagConfiguration;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  users: BooleanFlagConfiguration;
};

export type FeatureFlagInput = {
  id: Scalars['ID']['input'];
};

export enum FeatureFlagName {
  BUILD_ORDER_BUTTON = 'BUILD_ORDER_BUTTON',
  DAT_LOAD_SEARCH_AUTOMATION = 'DAT_LOAD_SEARCH_AUTOMATION',
  DAT_RATE_VIEW_AUTOMATION = 'DAT_RATE_VIEW_AUTOMATION',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  EMAIL_SUBSCRIPTIONS = 'EMAIL_SUBSCRIPTIONS',
  ENTITY_ASSOCIATION = 'ENTITY_ASSOCIATION',
  FETCH_AND_MERGE_LOCATION_DATA_ON_SUBMIT = 'FETCH_AND_MERGE_LOCATION_DATA_ON_SUBMIT',
  FORWARDED_EMAIL_CLASSIFICATION = 'FORWARDED_EMAIL_CLASSIFICATION',
  INTERCOM_SUPPORT = 'INTERCOM_SUPPORT',
  ORDER_PRESUBMIT_VALIDATION = 'ORDER_PRESUBMIT_VALIDATION',
  QUOTES_CONDITIONAL_AUTO_REPLY = 'QUOTES_CONDITIONAL_AUTO_REPLY',
  QUOTE_FREIGHT_MODE_FILTER = 'QUOTE_FREIGHT_MODE_FILTER',
  QUOTE_OPPORTUNITIES_VIEW = 'QUOTE_OPPORTUNITIES_VIEW',
  QUOTE_TRAIL = 'QUOTE_TRAIL',
  QUOTE_UPDATE_FROM_TMS_IMPORT = 'QUOTE_UPDATE_FROM_TMS_IMPORT',
  SHOW_THREAD_MESSAGE_CLASSIFICATIONS = 'SHOW_THREAD_MESSAGE_CLASSIFICATIONS'
}

export type FeatureFlagPayload = {
  __typename?: 'FeatureFlagPayload';
  featureFlag: FeatureFlag;
};

export type FetchBearerTokenInput = {
  integrationType: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type FetchBearerTokenPayload = {
  __typename?: 'FetchBearerTokenPayload';
  bearerToken: Scalars['String']['output'];
};

export type FileUpload = {
  author: User;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url: Scalars['String']['output'];
};

export type FlagOriginatorInput = {
  flag: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  numJobsToFlag?: InputMaybe<Scalars['Float']['input']>;
};

export type FlagOriginatorPayload = {
  __typename?: 'FlagOriginatorPayload';
  originator: Originator;
  success: Scalars['Boolean']['output'];
};

export type FlowTrigger = {
  __typename?: 'FlowTrigger';
  config?: Maybe<Scalars['JSON']['output']>;
  type: FlowTriggerType;
};

export type FlowTriggerInput = {
  config?: InputMaybe<Scalars['JSON']['input']>;
  type: FlowTriggerType;
};

export enum FlowTriggerType {
  CONFIRMED_QUOTE_CREATED = 'CONFIRMED_QUOTE_CREATED',
  ORDER_EVENT = 'ORDER_EVENT',
  QUOTE_EVENT = 'QUOTE_EVENT'
}

export type FrontFilter = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
};

export type FrontMessageData = {
  conversationId: Scalars['String']['input'];
};

export enum GenerateQuoteResponseMessageFailureReason {
  MISSING_QUOTE_DATA = 'MISSING_QUOTE_DATA',
  MISSING_RATE = 'MISSING_RATE'
}

export type GenerateQuoteResponseMessageInput = {
  instructions?: InputMaybe<Scalars['String']['input']>;
  quoteIds: Array<Scalars['String']['input']>;
};

export type GenerateQuoteResponseMessagePayload = {
  __typename?: 'GenerateQuoteResponseMessagePayload';
  errors?: Maybe<Array<GenerateQuoteResponseMessageFailureReason>>;
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GeoCircle = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  radiusMiles: Scalars['Float']['input'];
};

export type GetFeatureFlagEnabledInput = {
  name: FeatureFlagName;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type GmailFilter = {
  threadId?: InputMaybe<Scalars['String']['input']>;
};

export type GmailMessageData = {
  threadId: Scalars['String']['input'];
};

export type HasAuthorizedServiceTypePayload = {
  __typename?: 'HasAuthorizedServiceTypePayload';
  email?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type HasIntegrationCredentialsInput = {
  organizationId: Scalars['ID']['input'];
  type: IntegrationCredentialsType;
  usageIndicator: UsageIndicator;
};

export type HasIntegrationCredentialsPayload = {
  __typename?: 'HasIntegrationCredentialsPayload';
  hasIntegrationCredentials: Scalars['Boolean']['output'];
};

export type ImportTmsOrderInput = {
  data: Scalars['String']['input'];
  type: ExternalMessageType;
};

export type ImportTmsOrderPayload = {
  __typename?: 'ImportTmsOrderPayload';
  externalMessage?: Maybe<ExternalMessage>;
  success: Scalars['Boolean']['output'];
};

export enum IngestableExternalDataType {
  USER_MAPPINGS = 'USER_MAPPINGS'
}

/** The type of integration credentials. */
export enum IntegrationCredentialsType {
  DAT = 'DAT',
  FRONT = 'FRONT',
  GREENSCREENS = 'GREENSCREENS',
  HOSTED_MCLEOD = 'HOSTED_MCLEOD',
  MCLEOD = 'MCLEOD',
  STRATEGY_LIVE = 'STRATEGY_LIVE',
  TABI = 'TABI',
  TAI = 'TAI',
  TURVO = 'TURVO'
}

export type IntegrationOptions = {
  integrationType?: InputMaybe<Scalars['String']['input']>;
  tenantId?: InputMaybe<Scalars['String']['input']>;
};

export enum IntegrationType {
  ARCBEST_TL_QUOTING = 'ARCBEST_TL_QUOTING',
  DAT = 'DAT',
  ECHO = 'ECHO',
  EVANS = 'EVANS',
  GREENSCREENS = 'GREENSCREENS',
  HOSTED_MCLEOD = 'HOSTED_MCLEOD',
  MCLEOD = 'MCLEOD',
  MODE = 'MODE',
  NFI = 'NFI',
  ORDERFUL = 'ORDERFUL',
  STEDI = 'STEDI',
  STRATEGY_LIVE = 'STRATEGY_LIVE',
  SUNSET = 'SUNSET',
  TABI = 'TABI',
  TAI = 'TAI',
  TEST = 'TEST',
  TURVO = 'TURVO'
}

export type InviteUserToOrganizationInput = {
  email: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  role?: InputMaybe<OrganizationUserRole>;
};

export type InviteUserToOrganizationPayload = {
  __typename?: 'InviteUserToOrganizationPayload';
  organizationUserRelation: OrganizationUserRelation;
  success: Scalars['Boolean']['output'];
};

export type Job = AbstractEntity & Node & {
  __typename?: 'Job';
  confidence: Scalars['Float']['output'];
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  documents: Array<JobDocument>;
  /** Serialized as JSON */
  emailMetadata?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  jobDocuments: Array<JobDocument>;
  jobEvents: Array<JobEvent>;
  notes?: Maybe<Scalars['String']['output']>;
  orders: Array<Order>;
  organization: Organization;
  originator?: Maybe<Originator>;
  quotes: Array<Quote>;
  redactedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  source: JobSource;
  status: ProcessingStatus;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export enum JobConfirmationFilterStatus {
  ALL = 'ALL',
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED'
}

export type JobConnection = {
  __typename?: 'JobConnection';
  count: Scalars['Int']['output'];
  edges: Array<JobEdge>;
  nodes?: Maybe<Array<Job>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum JobCreationPolicy {
  ALWAYS_CREATE_NEW = 'ALWAYS_CREATE_NEW',
  DO_NOT_RECREATE = 'DO_NOT_RECREATE'
}

export enum JobCreationResultingAction {
  CREATED_NEW_JOB = 'CREATED_NEW_JOB',
  FAILED_TO_CREATE_JOB = 'FAILED_TO_CREATE_JOB',
  RETURNED_EXISTING_JOB = 'RETURNED_EXISTING_JOB'
}

export type JobDateFilter = {
  /** List Job that have been created at or before the given date. */
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** List Job that have been created at or after the given date. */
  start?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type JobDocument = AbstractEntity & Node & {
  __typename?: 'JobDocument';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<JobDocumentType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  upload?: Maybe<JobDocumentUpload>;
};

export enum JobDocumentType {
  EMAIL = 'EMAIL',
  OTHER = 'OTHER'
}

export type JobDocumentUpload = AbstractEntity & FileUpload & Node & {
  __typename?: 'JobDocumentUpload';
  author: User;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url: Scalars['String']['output'];
};

export type JobEdge = {
  __typename?: 'JobEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Job;
};

/** An event related to a job. */
export type JobEvent = AbstractEntity & Node & {
  __typename?: 'JobEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  jobId: Scalars['String']['output'];
  type: JobEventType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export enum JobEventType {
  AI_PROCESSING_COMPLETED = 'AI_PROCESSING_COMPLETED',
  AI_PROCESSING_FAILED = 'AI_PROCESSING_FAILED',
  AI_PROCESSING_STARTED = 'AI_PROCESSING_STARTED',
  AI_PROCESSING_TRIGGERED = 'AI_PROCESSING_TRIGGERED',
  CREATED_OPTIMISTICALLY = 'CREATED_OPTIMISTICALLY',
  ORIGINATOR_RESOLUTION_FAILED = 'ORIGINATOR_RESOLUTION_FAILED',
  ORIGINATOR_RESOLUTION_SUCCEEDED = 'ORIGINATOR_RESOLUTION_SUCCEEDED',
  USER_CONFIRMED = 'USER_CONFIRMED'
}

export enum JobSource {
  DEPRECATED_EMAIL_MONITORING = 'DEPRECATED_EMAIL_MONITORING',
  DEPRECATED_EMAIL_MONITORING_SHORT_CIRCUIT = 'DEPRECATED_EMAIL_MONITORING_SHORT_CIRCUIT',
  FORWARDED_EMAIL = 'FORWARDED_EMAIL',
  FRONT = 'FRONT',
  GMAIL = 'GMAIL',
  OUTLOOK = 'OUTLOOK',
  OUTLOOK_EMAIL_MONITORING = 'OUTLOOK_EMAIL_MONITORING',
  OUTLOOK_EMAIL_MONITORING_SHORT_CIRCUIT = 'OUTLOOK_EMAIL_MONITORING_SHORT_CIRCUIT',
  UNKNOWN = 'UNKNOWN',
  WEBAPP = 'WEBAPP'
}

export enum JobType {
  ORDERS = 'ORDERS',
  QUOTES = 'QUOTES'
}

export enum JobUpdateFailureReason {
  JOB_DELETED = 'JOB_DELETED'
}

export type JobsInput = {
  dateFilter?: InputMaybe<JobDateFilter>;
  frontFilter?: InputMaybe<FrontFilter>;
  gmailFilter?: InputMaybe<GmailFilter>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outlookFilter?: InputMaybe<OutlookFilter>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LabeledComputation = AbstractEntity & Node & {
  __typename?: 'LabeledComputation';
  createdAt: Scalars['DateTimeISO']['output'];
  data?: Maybe<LabeledComputationData>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['JSON']['output']>;
  labeledDataUrl: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  savedComputation: SavedComputation;
  source: LabeledComputationSource;
  type?: Maybe<LabeledComputationType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type LabeledComputationConnection = {
  __typename?: 'LabeledComputationConnection';
  count: Scalars['Int']['output'];
  edges: Array<LabeledComputationEdge>;
  nodes?: Maybe<Array<LabeledComputation>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type LabeledComputationData = {
  __typename?: 'LabeledComputationData';
  computationType: SavedComputationType;
  functionName: Scalars['String']['output'];
  inputs: Scalars['JSONObject']['output'];
  label?: Maybe<Scalars['JSON']['output']>;
  timestamp: Scalars['DateTimeISO']['output'];
};

export type LabeledComputationDataInput = {
  computationType: SavedComputationType;
  functionName: Scalars['String']['input'];
  inputs: Scalars['JSONObject']['input'];
  label?: InputMaybe<Scalars['JSON']['input']>;
  timestamp: Scalars['DateTimeISO']['input'];
};

export type LabeledComputationEdge = {
  __typename?: 'LabeledComputationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: LabeledComputation;
};

export enum LabeledComputationSource {
  INFERRED_FROM_USER_ACTION = 'INFERRED_FROM_USER_ACTION',
  MANUALLY_CREATED = 'MANUALLY_CREATED',
  UNKNOWN = 'UNKNOWN'
}

export enum LabeledComputationType {
  ADDRESS_PARSE = 'ADDRESS_PARSE',
  BUILD_ALL_QUOTES = 'BUILD_ALL_QUOTES',
  BUILD_ALL_QUOTES_COMPLETE = 'BUILD_ALL_QUOTES_COMPLETE',
  BUILD_ORDER = 'BUILD_ORDER',
  DECIDE_IGNORE_MESSAGE = 'DECIDE_IGNORE_MESSAGE',
  DECIDE_MESSAGE_ACTION = 'DECIDE_MESSAGE_ACTION',
  EMAIL_CLASSIFICATION = 'EMAIL_CLASSIFICATION',
  ENTITY_DISCRIMINATION = 'ENTITY_DISCRIMINATION',
  INFER_MISSING_ADDRESS_COMPONENT = 'INFER_MISSING_ADDRESS_COMPONENT',
  LOCATION_MATCH = 'LOCATION_MATCH',
  ORIGINATOR_RESOLUTION = 'ORIGINATOR_RESOLUTION',
  RESOLVE_ORIGINATOR_AND_CONTACT = 'RESOLVE_ORIGINATOR_AND_CONTACT',
  TEMPLATE_COMPLETION = 'TEMPLATE_COMPLETION'
}

export type LabeledComputationsInput = {
  computationTypes?: InputMaybe<Array<SavedComputationType>>;
  externalMessageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  jobIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  labelTypes?: InputMaybe<Array<LabeledComputationType>>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  quoteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  savedComputationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type LaneFilter = {
  destination?: InputMaybe<GeoCircle>;
  origin?: InputMaybe<GeoCircle>;
};

export type LatLng = {
  __typename?: 'LatLng';
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type LocationAddressFilter = {
  addressOne?: InputMaybe<Scalars['String']['input']>;
  addressTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type LocationAddressInput = {
  addressOne: Scalars['String']['input'];
  addressTwo?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state: Scalars['String']['input'];
  zip: Scalars['String']['input'];
};

export type LocationSettings = {
  __typename?: 'LocationSettings';
  voomaCreatedLocationOwnershipPolicy?: Maybe<CreatedLocationOwnershipPolicy>;
  webhookCreatedLocationOwnershipPolicy?: Maybe<CreatedLocationOwnershipPolicy>;
};

export type LocationSettingsInput = {
  voomaCreatedLocationOwnershipPolicy?: InputMaybe<CreatedLocationOwnershipPolicy>;
  webhookCreatedLocationOwnershipPolicy?: InputMaybe<CreatedLocationOwnershipPolicy>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success: Scalars['Boolean']['output'];
};

export type MarketConditionsInput = {
  id: Scalars['ID']['input'];
};

export type MarketConditionsPayload = {
  __typename?: 'MarketConditionsPayload';
  errorMessage?: Maybe<Scalars['String']['output']>;
  integrationType: IntegrationType;
  marketConditions: Array<StopMarketConditions>;
  stop?: Maybe<Scalars['JSON']['output']>;
};

export type MemoriesInput = {
  computationTypes?: InputMaybe<Array<SavedComputationType>>;
  organizationIds: Array<Scalars['ID']['input']>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type Memory = AbstractEntity & Node & {
  __typename?: 'Memory';
  comment?: Maybe<Scalars['String']['output']>;
  computationType?: Maybe<SavedComputationType>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  labeledComputation: LabeledComputation;
  organization: Organization;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type MemoryConnection = {
  __typename?: 'MemoryConnection';
  count: Scalars['Int']['output'];
  edges: Array<MemoryEdge>;
  nodes?: Maybe<Array<Memory>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MemoryEdge = {
  __typename?: 'MemoryEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Memory;
};

export type MessageEntityAssociation = AbstractEntity & Node & {
  __typename?: 'MessageEntityAssociation';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalMessage: ExternalMessage;
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  quote?: Maybe<Quote>;
  scores?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type MessageEntityAssociationConnection = {
  __typename?: 'MessageEntityAssociationConnection';
  count: Scalars['Int']['output'];
  edges: Array<MessageEntityAssociationEdge>;
  nodes?: Maybe<Array<MessageEntityAssociation>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MessageEntityAssociationEdge = {
  __typename?: 'MessageEntityAssociationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: MessageEntityAssociation;
};

export type MessageEntityAssociationsInput = {
  entityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityTypes?: InputMaybe<Array<TemplateType>>;
  messageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MessageOrderAssociation = AbstractEntity & Node & {
  __typename?: 'MessageOrderAssociation';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalMessage: ExternalMessage;
  id: Scalars['ID']['output'];
  order: Order;
  quote?: Maybe<Quote>;
  scores?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type MessageQuoteAssociation = AbstractEntity & Node & {
  __typename?: 'MessageQuoteAssociation';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalMessage: ExternalMessage;
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  quote: Quote;
  scores?: Maybe<Scalars['JSON']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type MessageQuoteAssociationConnection = {
  __typename?: 'MessageQuoteAssociationConnection';
  count: Scalars['Int']['output'];
  edges: Array<MessageQuoteAssociationEdge>;
  nodes?: Maybe<Array<MessageQuoteAssociation>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type MessageQuoteAssociationEdge = {
  __typename?: 'MessageQuoteAssociationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: MessageQuoteAssociation;
};

export type MessageQuoteAssociationsInput = {
  entityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  messageIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ModelConfig = {
  __typename?: 'ModelConfig';
  computerVisionModel?: Maybe<ComputerVisionModelName>;
  documentInterpretationModel?: Maybe<DocumentInterpretationModelName>;
  groupByFields?: Maybe<Array<Scalars['String']['output']>>;
  multiOrderBuildStrategy?: Maybe<MultiOrderBuildStrategy>;
  replicationModel?: Maybe<DocumentInterpretationModelName>;
};

export type ModelConfigInput = {
  computerVisionModel?: InputMaybe<ComputerVisionModelName>;
  documentInterpretationModel?: InputMaybe<DocumentInterpretationModelName>;
  groupByFields?: InputMaybe<Array<Scalars['String']['input']>>;
  multiOrderBuildStrategy?: InputMaybe<MultiOrderBuildStrategy>;
  replicationModel?: InputMaybe<DocumentInterpretationModelName>;
};

export type MoveUserToOrganizationsInput = {
  organizationIds: Array<Scalars['ID']['input']>;
  userId: Scalars['String']['input'];
};

export type MoveUserToOrganizationsPayload = {
  __typename?: 'MoveUserToOrganizationsPayload';
  success: Scalars['Boolean']['output'];
};

export enum MultiOrderBuildStrategy {
  BUILD_ONE_AND_REPLICATE = 'BUILD_ONE_AND_REPLICATE',
  INTELLIGENT_PARTITIONING = 'INTELLIGENT_PARTITIONING',
  ONE_ORDER_PER_PAGE = 'ONE_ORDER_PER_PAGE',
  ONE_ORDER_PER_PDF = 'ONE_ORDER_PER_PDF',
  PARTITION_CSV = 'PARTITION_CSV'
}

export type Mutation = {
  __typename?: 'Mutation';
  addDocumentToJob: AddDocumentToJobPayload;
  addDocumentToOrder: AddDocumentToOrderPayload;
  addUserToOrganization: AddUserToOrganizationPayload;
  bulkCreatePlaces: BulkCreatePlacesPayload;
  bulkDeleteJobs: BulkDeleteJobsPayload;
  bulkDeleteOrders: BulkDeleteOrdersPayload;
  bulkDeleteOriginators: BulkDeleteOriginatorsPayload;
  bulkDeletePlaces: BulkDeletePlacesPayload;
  bulkDeleteQuotes: BulkDeleteQuotesPayload;
  computeRates: ComputeRatesPayload;
  copyJobToComplementary: CopyJobToComplementaryPayload;
  createAuthToken: CreateAuthTokenPayload;
  createCarrierRate: CreateCarrierRatePayload;
  createContact: CreateContactPayload;
  createCustomerRate: CreateCustomerRatePayload;
  createEvent: CreateBaseEventPayload;
  createExternalIdMapping?: Maybe<CreateExternalIdMappingPayload>;
  createExternalMessageJob: CreateJobPayload;
  createFrontJob: CreateJobPayload;
  createFrontReplyAll: CreateFrontReplyAllPayload;
  createGmailJob: CreateJobPayload;
  createJob: CreateJobPayload;
  createJobEvent: CreateJobEventPayload;
  createLabeledComputation: CreateLabeledComputationPayload;
  createMemory: CreateMemoryPayload;
  createMessageQuoteAssociation: CreateMessageQuoteAssociationPayload;
  createOrUpdateIntegrationCredentials: CreateOrUpdateIntegrationCredentialsPayload;
  createOrder: CreateOrderPayload;
  createOrderEvent: CreateOrderEventPayload;
  createOrganization: CreateOrganizationPayload;
  createOrganizationUserRelation: CreateOrganizationUserRelationPayload;
  createOriginator: CreateOriginatorPayload;
  createOriginatorEvent: CreateOriginatorEventPayload;
  createOutlookJob: CreateJobPayload;
  createOutlookReplyAll: CreateOutlookReplyAllPayload;
  createPlace: CreatePlacePayload;
  createQuote: CreateQuotePayload;
  createQuoteEvent: CreateQuoteEventPayload;
  createQuoteNote: CreateQuoteNotePayload;
  createQuotesReport: CreateQuotesReportPayload;
  createSavedComputation: CreateSavedComputationPayload;
  createTemplate: CreateTemplatePayload;
  createTransformationConfig: CreateTransformationConfigPayload;
  createTransformationDefinition: CreateTransformationDefinitionPayload;
  createWorkflow: CreateWorkflowPayload;
  deleteCarrierRate: DeleteCarrierRatePayload;
  deleteCustomerRate: DeleteCustomerRatePayload;
  deleteJob: DeleteJobPayload;
  deleteJobDocument: DeleteJobDocumentPayload;
  deleteLabeledComputation: DeleteLabeledComputationPayload;
  deleteMemory: DeleteMemoryPayload;
  deleteOrder: DeleteOrderPayload;
  deleteOriginator: DeleteOriginatorPayload;
  deletePlace: DeletePlacePayload;
  deleteQuote: DeleteQuotePayload;
  deleteTemplate: DeleteTemplatePayload;
  deleteTransformationConfig: DeleteTransformationConfigPayload;
  deleteTransformationDefinition: DeleteTransformationDefinitionPayload;
  deleteWorkflow: DeleteWorkflowPayload;
  findOrCreateMarketConditions: Array<MarketConditionsPayload>;
  flagOriginator: FlagOriginatorPayload;
  importTmsOrder: ImportTmsOrderPayload;
  inviteUserToOrganization: InviteUserToOrganizationPayload;
  logout: LogoutResponse;
  moveUserToOrganizations: MoveUserToOrganizationsPayload;
  onboardNewOriginator: OnboardNewOriginatorPayload;
  page: Scalars['Boolean']['output'];
  redactOrganizationData: RedactOrganizationDataPayload;
  refreshAuthTokenForUser: Scalars['Boolean']['output'];
  refreshOrderEntities: RefreshOrderEntitiesPayload;
  removeSenderFromOriginator: RemoveSenderFromOriginatorPayload;
  removeUserFromOrganization: RemoveUserFromOrganizationPayload;
  repeatOrder: RepeatOrderPayload;
  replicateOrder: ReplicateOrderPayload;
  reprocessOrder: ReprocessOrderPayload;
  sendOutlookMessage: SendOutlookMessagePayload;
  submitOrder: SubmitOrderPayload;
  syncTemplate: SyncTemplatePayload;
  track: Scalars['Boolean']['output'];
  triggerExternalDataIngest: TriggerExternalDataIngestPayload;
  triggerPlaceImport: TriggerPlaceImportPayload;
  triggerPlaceIngest: TriggerPlaceIngestPayload;
  updateCarrierRate: UpdateCarrierRatePayload;
  updateContact: UpdateContactPayload;
  updateCustomerRate: UpdateCustomerRatePayload;
  updateExternalMessage: UpdateExternalMessagePayload;
  updateFeatureFlag: FeatureFlagPayload;
  updateJob: UpdateJobPayload;
  updateLabeledComputation: CreateLabeledComputationPayload;
  updateMember: UpdateMemberPayload;
  updateMemory: UpdateMemoryPayload;
  updateOrder: UpdateOrderPayload;
  updateOrganization: UpdateOrganizationPayload;
  updateOriginator: UpdateOriginatorPayload;
  updatePlace: UpdatePlacePayload;
  updateQuote: UpdateQuotePayload;
  updateTemplate: UpdateTemplatePayload;
  updateTemplateUsingOrder: UpdateTemplateUsingOrderPayload;
  updateTransformationConfig: UpdateTransformationConfigPayload;
  updateTransformationDefinition: UpdateTransformationDefinitionPayload;
  updateUser: UpdateUserPayload;
  updateUserFeatures: UpdateUserPayload;
  updateUserSettings: UpdateUserPayload;
  updateWorkflow: UpdateWorkflowPayload;
  uploadFile: UploadFilePayload;
};


export type MutationAddDocumentToJobArgs = {
  input: AddDocumentToJobInput;
};


export type MutationAddDocumentToOrderArgs = {
  input: AddDocumentToOrderInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationBulkCreatePlacesArgs = {
  input: BulkCreatePlacesInput;
};


export type MutationBulkDeleteJobsArgs = {
  input: BulkDeleteJobsInput;
};


export type MutationBulkDeleteOrdersArgs = {
  input: BulkDeleteOrdersInput;
};


export type MutationBulkDeleteOriginatorsArgs = {
  input: BulkDeleteOriginatorsInput;
};


export type MutationBulkDeletePlacesArgs = {
  input: BulkDeletePlacesInput;
};


export type MutationBulkDeleteQuotesArgs = {
  input: BulkDeleteQuotesInput;
};


export type MutationComputeRatesArgs = {
  input: ComputeRatesInput;
};


export type MutationCopyJobToComplementaryArgs = {
  input: CopyJobToComplementaryInput;
};


export type MutationCreateAuthTokenArgs = {
  input: CreateAuthTokenInput;
};


export type MutationCreateCarrierRateArgs = {
  input: CreateCarrierRateInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateCustomerRateArgs = {
  input: CreateCustomerRateInput;
};


export type MutationCreateEventArgs = {
  input: CreateBaseEventInput;
};


export type MutationCreateExternalIdMappingArgs = {
  input: CreateExternalIdMappingInput;
};


export type MutationCreateExternalMessageJobArgs = {
  input: CreateExternalMessageJobInput;
};


export type MutationCreateFrontJobArgs = {
  input: CreateFrontJobInput;
};


export type MutationCreateFrontReplyAllArgs = {
  input: CreateFrontReplyAllInput;
};


export type MutationCreateGmailJobArgs = {
  input: CreateGmailJobInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobEventArgs = {
  input: CreateJobEventInput;
};


export type MutationCreateLabeledComputationArgs = {
  input: CreateLabeledComputationInput;
};


export type MutationCreateMemoryArgs = {
  input: CreateMemoryInput;
};


export type MutationCreateMessageQuoteAssociationArgs = {
  input: CreateMessageQuoteAssociationInput;
};


export type MutationCreateOrUpdateIntegrationCredentialsArgs = {
  input: CreateOrUpdateIntegrationCredentialsInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateOrderEventArgs = {
  input: CreateOrderEventInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationUserRelationArgs = {
  input: CreateOrganizationUserRelationInput;
};


export type MutationCreateOriginatorArgs = {
  input: CreateOriginatorInput;
};


export type MutationCreateOriginatorEventArgs = {
  input: CreateOriginatorEventInput;
};


export type MutationCreateOutlookJobArgs = {
  input: CreateOutlookJobInput;
};


export type MutationCreateOutlookReplyAllArgs = {
  input: CreateOutlookReplyAllInput;
};


export type MutationCreatePlaceArgs = {
  input: CreatePlaceInput;
};


export type MutationCreateQuoteArgs = {
  input: CreateQuoteInput;
};


export type MutationCreateQuoteEventArgs = {
  input: CreateQuoteEventInput;
};


export type MutationCreateQuoteNoteArgs = {
  input: CreateQuoteNoteInput;
};


export type MutationCreateQuotesReportArgs = {
  input: QuotesInput;
};


export type MutationCreateSavedComputationArgs = {
  input: CreateSavedComputationInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationCreateTransformationConfigArgs = {
  input: CreateTransformationConfigInput;
};


export type MutationCreateTransformationDefinitionArgs = {
  input: CreateTransformationDefinitionInput;
};


export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput;
};


export type MutationDeleteCarrierRateArgs = {
  input: DeleteCarrierRateInput;
};


export type MutationDeleteCustomerRateArgs = {
  input: DeleteCustomerRateInput;
};


export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};


export type MutationDeleteJobDocumentArgs = {
  input: DeleteJobDocumentInput;
};


export type MutationDeleteLabeledComputationArgs = {
  input: DeleteLabeledComputationInput;
};


export type MutationDeleteMemoryArgs = {
  input: DeleteMemoryInput;
};


export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};


export type MutationDeleteOriginatorArgs = {
  input: DeleteOriginatorInput;
};


export type MutationDeletePlaceArgs = {
  input: DeletePlaceInput;
};


export type MutationDeleteQuoteArgs = {
  input: DeleteQuoteInput;
};


export type MutationDeleteTemplateArgs = {
  input: DeleteTemplateInput;
};


export type MutationDeleteTransformationConfigArgs = {
  input: DeleteTransformationConfigInput;
};


export type MutationDeleteTransformationDefinitionArgs = {
  input: DeleteTransformationDefinitionInput;
};


export type MutationDeleteWorkflowArgs = {
  input: DeleteWorkflowInput;
};


export type MutationFindOrCreateMarketConditionsArgs = {
  input: MarketConditionsInput;
};


export type MutationFlagOriginatorArgs = {
  input: FlagOriginatorInput;
};


export type MutationImportTmsOrderArgs = {
  input: ImportTmsOrderInput;
};


export type MutationInviteUserToOrganizationArgs = {
  input: InviteUserToOrganizationInput;
};


export type MutationMoveUserToOrganizationsArgs = {
  input: MoveUserToOrganizationsInput;
};


export type MutationOnboardNewOriginatorArgs = {
  input: OnboardNewOriginatorInput;
};


export type MutationPageArgs = {
  input: PageInput;
};


export type MutationRedactOrganizationDataArgs = {
  input: RedactOrganizationDataInput;
};


export type MutationRefreshAuthTokenForUserArgs = {
  type: AuthTokenType;
  userId: Scalars['String']['input'];
};


export type MutationRefreshOrderEntitiesArgs = {
  input: RefreshOrderEntitiesInput;
};


export type MutationRemoveSenderFromOriginatorArgs = {
  input: RemoveSenderFromOriginatorInput;
};


export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveUserFromOrganizationInput;
};


export type MutationRepeatOrderArgs = {
  input: RepeatOrderInput;
};


export type MutationReplicateOrderArgs = {
  input: ReplicateOrderInput;
};


export type MutationReprocessOrderArgs = {
  input: ReprocessOrderInput;
};


export type MutationSendOutlookMessageArgs = {
  input: SendOutlookMessageInput;
};


export type MutationSubmitOrderArgs = {
  input: SubmitOrderInput;
};


export type MutationSyncTemplateArgs = {
  input: SyncTemplateInput;
};


export type MutationTrackArgs = {
  input: TrackInput;
};


export type MutationTriggerExternalDataIngestArgs = {
  input: TriggerExternalDataIngestInput;
};


export type MutationTriggerPlaceImportArgs = {
  input: TriggerPlaceImportInput;
};


export type MutationTriggerPlaceIngestArgs = {
  input: TriggerPlaceIngestInput;
};


export type MutationUpdateCarrierRateArgs = {
  input: UpdateCarrierRateInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateCustomerRateArgs = {
  input: UpdateCustomerRateInput;
};


export type MutationUpdateExternalMessageArgs = {
  input: UpdateExternalMessageInput;
};


export type MutationUpdateFeatureFlagArgs = {
  input: UpdateFeatureFlagInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateLabeledComputationArgs = {
  input: UpdateLabeledComputationInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateMemoryArgs = {
  input: UpdateMemoryInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOriginatorArgs = {
  input: UpdateOriginatorInput;
};


export type MutationUpdatePlaceArgs = {
  input: UpdatePlaceInput;
};


export type MutationUpdateQuoteArgs = {
  input: UpdateQuoteInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};


export type MutationUpdateTemplateUsingOrderArgs = {
  input: UpdateTemplateUsingOrderInput;
};


export type MutationUpdateTransformationConfigArgs = {
  input: UpdateTransformationConfigInput;
};


export type MutationUpdateTransformationDefinitionArgs = {
  input: UpdateTransformationDefinitionInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserFeaturesArgs = {
  input: UpdateUserFeaturesInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};


export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload']['input'];
  input: UploadFileInput;
};

export type NavigatorFields = {
  appName?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

/** An object with an ID */
export type Node = {
  id: Scalars['ID']['output'];
};

export type NotesFieldConfig = {
  __typename?: 'NotesFieldConfig';
  notes: Scalars['String']['output'];
  policy: ConfiguredNotesPolicy;
};

export type NotesFieldConfigInput = {
  notes: Scalars['String']['input'];
  policy: ConfiguredNotesPolicy;
};

export type OnboardNewOriginatorInput = {
  autoSubmitScope?: InputMaybe<AutoSubmitScope>;
  configOverrides?: InputMaybe<Scalars['JSON']['input']>;
  expectedWeeklyOrderCount?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  orderNotesConfig?: InputMaybe<NotesFieldConfigInput>;
  organizationId: Scalars['ID']['input'];
  quoteAutoReplyScope?: InputMaybe<QuoteAutoReplyScope>;
  status?: InputMaybe<OriginatorStatus>;
  transmitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  voomaSupportNotes?: InputMaybe<Scalars['String']['input']>;
};

export type OnboardNewOriginatorPayload = {
  __typename?: 'OnboardNewOriginatorPayload';
  customerFacingOriginator: Originator;
  shadowOriginator: Originator;
  success: Scalars['Boolean']['output'];
};

export type Order = AbstractEntity & Node & {
  __typename?: 'Order';
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTimeISO']['output'];
  createdAtDisplay?: Maybe<Scalars['DateTimeISO']['output']>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  documents: Array<JobDocument>;
  externalDeepLink?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  /** Serialized as JSON */
  extractedData?: Maybe<Scalars['String']['output']>;
  hasSubmittedEvent: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  job: Job;
  notes?: Maybe<Scalars['String']['output']>;
  orderEvents: Array<OrderEvent>;
  organization: Organization;
  originator?: Maybe<Originator>;
  primaryReference?: Maybe<Scalars['String']['output']>;
  processingStatus: ProcessingStatus;
  transmitId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  count: Scalars['Int']['output'];
  edges: Array<OrderEdge>;
  nodes?: Maybe<Array<Order>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrderDateFilter = {
  /** List Order that have been created at or before the given date. */
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** List Order that have been created at or after the given date. */
  start?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Order;
};

export type OrderEvent = AbstractEntity & Node & {
  __typename?: 'OrderEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  /** Serialized as JSON */
  metadata?: Maybe<Scalars['JSON']['output']>;
  orderId: Scalars['String']['output'];
  type: OrderEventType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export enum OrderEventType {
  AI_INFERENCE_COMPLETED = 'AI_INFERENCE_COMPLETED',
  AI_PROCESSING_COMPLETED = 'AI_PROCESSING_COMPLETED',
  AI_PROCESSING_FAILED = 'AI_PROCESSING_FAILED',
  AI_PROCESSING_STARTED = 'AI_PROCESSING_STARTED',
  AI_PROCESSING_TRIGGERED = 'AI_PROCESSING_TRIGGERED',
  AUTO_SUBMIT_CONDITIONS_CHECK_FAILED = 'AUTO_SUBMIT_CONDITIONS_CHECK_FAILED',
  CREATED_OPTIMISTICALLY = 'CREATED_OPTIMISTICALLY',
  EXTRACTED_DATA_EDITED = 'EXTRACTED_DATA_EDITED',
  ORIGINATOR_UPDATED = 'ORIGINATOR_UPDATED',
  SUBMITTED = 'SUBMITTED',
  TMS_SUBMISSION_FAILED = 'TMS_SUBMISSION_FAILED',
  USER_CONFIRMED = 'USER_CONFIRMED'
}

export type OrderSupportNotificationsInput = {
  id: Scalars['String']['input'];
};

export type OrderSupportNotificationsPayload = {
  __typename?: 'OrderSupportNotificationsPayload';
  notifications: Array<Scalars['String']['output']>;
};

export enum OrderUpdateFailureReason {
  DATA_CORRUPTED = 'DATA_CORRUPTED',
  ORDER_DELETED = 'ORDER_DELETED'
}

export type OrdersInput = {
  dateFilter?: InputMaybe<OrderDateFilter>;
  hasBeenSubmitted?: InputMaybe<Scalars['Boolean']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outlookFilter?: InputMaybe<OutlookFilter>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** A company that provides truck transportation services. */
export type Organization = AbstractEntity & Node & {
  __typename?: 'Organization';
  configurableOptions?: Maybe<ConfigurableOptions>;
  createdAt: Scalars['DateTimeISO']['output'];
  /** The customer organization for this shadow organization */
  customerOrganization?: Maybe<Organization>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  emailAuthPolicy?: Maybe<EmailAuthPolicy>;
  emailDomains?: Maybe<Array<Scalars['String']['output']>>;
  enabledFeatureFlags: Array<OrganizationFeatureFlagPayload>;
  expectedWeeklyOrderCount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  integrationConfigs?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  products: Array<Product>;
  retentionPeriodHours?: Maybe<Scalars['Int']['output']>;
  settings: OrganizationSettings;
  /** The shadow organization for this customer organization */
  shadowOrganization?: Maybe<Organization>;
  type?: Maybe<OrganizationType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  users: Array<OrganizationUserRelation>;
};

export type OrganizationByIntegrationIdInput = {
  integrationType: Scalars['String']['input'];
  tenantId: Scalars['String']['input'];
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  count: Scalars['Int']['output'];
  edges: Array<OrganizationEdge>;
  nodes?: Maybe<Array<Organization>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Organization;
};

export type OrganizationFeatureFlagPayload = {
  __typename?: 'OrganizationFeatureFlagPayload';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  emailScanning?: Maybe<EmailScanningSettings>;
  locations?: Maybe<LocationSettings>;
};

export type OrganizationSettingsInput = {
  emailScanning?: InputMaybe<EmailScanningSettingsInput>;
  locations?: InputMaybe<LocationSettingsInput>;
};

export enum OrganizationType {
  CUSTOMER = 'CUSTOMER',
  SHADOW = 'SHADOW'
}

/** A relation between an Organization and a user. */
export type OrganizationUserRelation = AbstractEntity & Node & {
  __typename?: 'OrganizationUserRelation';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  roles: Array<OrganizationUserRole>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user: User;
  userId: Scalars['String']['output'];
};

/** Connection for organization <> user relations. */
export type OrganizationUserRelationConnection = {
  __typename?: 'OrganizationUserRelationConnection';
  count: Scalars['Int']['output'];
  edges: Array<OrganizationUserRelationEdge>;
  nodes?: Maybe<Array<OrganizationUserRelation>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OrganizationUserRelationEdge = {
  __typename?: 'OrganizationUserRelationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: OrganizationUserRelation;
};

export type OrganizationUserRelationsInput = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<OrganizationUserRole>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export enum OrganizationUserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER',
  TEST_ONLY_READ_ONLY = 'TEST_ONLY_READ_ONLY'
}

export type OrganizationsInput = {
  integration?: InputMaybe<IntegrationOptions>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type Originator = AbstractEntity & Node & {
  __typename?: 'Originator';
  autoSubmitScope: AutoSubmitScope;
  /** Serialized as JSON */
  configOverrides?: Maybe<Scalars['JSON']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  expectedWeeklyOrderCount?: Maybe<Scalars['Float']['output']>;
  flagged: Scalars['Boolean']['output'];
  hasExtractedDataConfigured: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  knownSenders: Array<Contact>;
  name: Scalars['String']['output'];
  orderNotesConfig?: Maybe<NotesFieldConfig>;
  organization: Organization;
  quoteAutoReplyScope: QuoteAutoReplyScope;
  status: OriginatorStatus;
  templates: Array<Template>;
  transmitIds: Array<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  voomaSupportNotes: Scalars['String']['output'];
  workflows: Array<Workflow>;
};

export type OriginatorConfiguration = {
  __typename?: 'OriginatorConfiguration';
  status: OriginatorStatus;
};

export type OriginatorConnection = {
  __typename?: 'OriginatorConnection';
  count: Scalars['Int']['output'];
  edges: Array<OriginatorEdge>;
  nodes?: Maybe<Array<Originator>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OriginatorEdge = {
  __typename?: 'OriginatorEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Originator;
};

export type OriginatorEvent = AbstractEntity & Node & {
  __typename?: 'OriginatorEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  data?: Maybe<OriginatorEventData>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  name: OriginatorEventType;
  originator: Originator;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  userId?: Maybe<User>;
};

export type OriginatorEventConnection = {
  __typename?: 'OriginatorEventConnection';
  count: Scalars['Int']['output'];
  edges: Array<OriginatorEventEdge>;
  nodes?: Maybe<Array<OriginatorEvent>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type OriginatorEventData = {
  __typename?: 'OriginatorEventData';
  configurationState?: Maybe<OriginatorConfiguration>;
};

export type OriginatorEventEdge = {
  __typename?: 'OriginatorEventEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: OriginatorEvent;
};

export enum OriginatorEventType {
  EXTRACTED_FIELDS_CONFIGURED = 'EXTRACTED_FIELDS_CONFIGURED'
}

export type OriginatorEventsInput = {
  originatorId?: InputMaybe<Scalars['String']['input']>;
};

export enum OriginatorStatus {
  PRODUCTION = 'PRODUCTION',
  TEST = 'TEST'
}

export type OriginatorsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OriginatorStatus>>;
  transmitIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OutlookFilter = {
  conversationId?: InputMaybe<Scalars['String']['input']>;
  sharedInbox?: InputMaybe<Scalars['String']['input']>;
};

export type OutlookMessageData = {
  /** Outlook item.itemId converted to REST format */
  itemId: Scalars['String']['input'];
  /** Use itemId instead. */
  messageId: Scalars['String']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PageInput = {
  context: AnalyticsContext;
  name: Scalars['String']['input'];
  properties?: InputMaybe<PageProperties>;
};

export type PageProperties = {
  carrierRateId?: InputMaybe<Scalars['String']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  originatorId?: InputMaybe<Scalars['String']['input']>;
  originatorName?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ParsedEmail = {
  __typename?: 'ParsedEmail';
  attachments: Array<EmailAttachment>;
  html: Scalars['String']['output'];
  metadata: EmailHeaderMetadata;
};

/** A location, such as a warehouse or distribution center. */
export type Place = AbstractEntity & Node & {
  __typename?: 'Place';
  address?: Maybe<PlaceAddress>;
  aliases?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTimeISO']['output'];
  creationProvenance?: Maybe<PlaceCreationProvenance>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  locationCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notesConfig?: Maybe<NotesFieldConfig>;
  organization: Organization;
  originator?: Maybe<Originator>;
  tags: Array<PlaceTag>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

/** The address associated with a place. */
export type PlaceAddress = {
  __typename?: 'PlaceAddress';
  /** Main address, typically contains street and building number. Ex: 1234 Main Street. */
  addressOne?: Maybe<Scalars['String']['output']>;
  /** Optional address line 2, typically used for apartment/suite number. */
  addressTwo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type PlaceAddressInput = {
  /** Main address, typically contains street and building number. Ex: 1234 Main Street. */
  addressOne?: InputMaybe<Scalars['String']['input']>;
  /** Optional address line 2, typically used for apartment/suite number. */
  addressTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

/** Connection type for a place, such as a warehouse or distribution center. */
export type PlaceConnection = {
  __typename?: 'PlaceConnection';
  count: Scalars['Int']['output'];
  edges: Array<PlaceEdge>;
  nodes?: Maybe<Array<Place>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum PlaceCreationFailureReason {
  DUPLICATE = 'DUPLICATE'
}

export enum PlaceCreationProvenance {
  FROM_CUSTOMER = 'FROM_CUSTOMER',
  UNKNOWN = 'UNKNOWN',
  VOOMA_AI_GENERATED = 'VOOMA_AI_GENERATED',
  VOOMA_MANUALLY_CREATED = 'VOOMA_MANUALLY_CREATED'
}

export type PlaceEdge = {
  __typename?: 'PlaceEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Place;
};

export enum PlaceTag {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP'
}

export type PlacesInput = {
  address?: InputMaybe<LocationAddressFilter>;
  locationCode?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<PlaceTag>>;
};

export type PricingStrategy = AbstractEntity & Node & {
  __typename?: 'PricingStrategy';
  config: Scalars['JSON']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  organization: Organization;
  originator?: Maybe<Originator>;
  type: PricingStrategyType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user: User;
};

export enum PricingStrategyType {
  SIMPLE_MARGIN = 'SIMPLE_MARGIN'
}

/** Processing status, generally used for long-running jobs */
export enum ProcessingStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  NEW = 'NEW',
  PROCESSING = 'PROCESSING'
}

export enum Product {
  EMAIL_SCANNING = 'EMAIL_SCANNING',
  ORDERS = 'ORDERS',
  QUOTES = 'QUOTES'
}

export type Query = {
  __typename?: 'Query';
  contacts: ContactConnection;
  conversationActions: Array<AgentAction>;
  conversationExternalMessages: Array<ExternalMessage>;
  downloadArtifacts: DownloadArtifactsPayload;
  downloadOrder: DownloadOrderPayload;
  events: BaseEventConnection;
  externalMessageById: ExternalMessagePayload;
  featureFlagById: FeatureFlagPayload;
  featureFlags: Array<FeatureFlag>;
  fetchBearerToken: FetchBearerTokenPayload;
  generateQuoteResponseMessage: GenerateQuoteResponseMessagePayload;
  getOrderDashboardEmbedUrl: Scalars['String']['output'];
  getOrganizationProducts: Array<Product>;
  getQuoteDashboardEmbedUrl: Scalars['String']['output'];
  hasAuthorizedServiceType: HasAuthorizedServiceTypePayload;
  hasIntegrationCredentials: HasIntegrationCredentialsPayload;
  isFeatureFlagEnabled: Scalars['Boolean']['output'];
  jobById: Job;
  jobs: JobConnection;
  labeledComputationById: LabeledComputation;
  labeledComputations: LabeledComputationConnection;
  me?: Maybe<User>;
  memories: MemoryConnection;
  memoryById: Memory;
  messageEntityAssociations: MessageEntityAssociationConnection;
  messageQuoteAssociations: MessageQuoteAssociationConnection;
  newExternalMessages: Array<ExternalMessage>;
  orderById: Order;
  orderDocuments: Array<JobDocument>;
  orderSupportNotifications: OrderSupportNotificationsPayload;
  orders: OrderConnection;
  organizationById: Organization;
  organizationByIntegrationId: Organization;
  organizationUserRelations: OrganizationUserRelationConnection;
  organizations: OrganizationConnection;
  originatorById: Originator;
  originatorEvents: OriginatorEventConnection;
  originators: OriginatorConnection;
  placeById: Place;
  places: PlaceConnection;
  quoteById: Quote;
  quotes: QuoteConnection;
  quotesStats: Array<QuotesStat>;
  relationById: OrganizationUserRelation;
  savedComputationById: SavedComputation;
  savedComputations: SavedComputationConnection;
  templateById: Template;
  templates: TemplateConnection;
  templatesByOrganization: Array<Template>;
  templatesByOriginator: Array<Template>;
  transformationConfigById: TransformationConfig;
  transformationConfigs: TransformationConfigConnection;
  transformationDefinitionById: TransformationDefinition;
  transformationDefinitions: TransformationDefinitionConnection;
  userByEmail?: Maybe<User>;
  userById: User;
  users: UserConnection;
  workflows: WorkflowConnection;
  workflowsByOriginator: Array<Workflow>;
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<ContactsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConversationActionsArgs = {
  input: ConversationExternalMessagesInput;
};


export type QueryConversationExternalMessagesArgs = {
  input: ConversationExternalMessagesInput;
};


export type QueryDownloadArtifactsArgs = {
  input: DownloadArtifactsInput;
};


export type QueryDownloadOrderArgs = {
  input: DownloadOrderInput;
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<BaseEventsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryExternalMessageByIdArgs = {
  input: ExternalMessageInput;
};


export type QueryFeatureFlagByIdArgs = {
  input: FeatureFlagInput;
};


export type QueryFetchBearerTokenArgs = {
  input: FetchBearerTokenInput;
};


export type QueryGenerateQuoteResponseMessageArgs = {
  input: GenerateQuoteResponseMessageInput;
};


export type QueryGetOrganizationProductsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHasAuthorizedServiceTypeArgs = {
  type: AuthTokenType;
};


export type QueryHasIntegrationCredentialsArgs = {
  input: HasIntegrationCredentialsInput;
};


export type QueryIsFeatureFlagEnabledArgs = {
  input: GetFeatureFlagEnabledInput;
};


export type QueryJobByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<JobsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLabeledComputationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLabeledComputationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<LabeledComputationsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMemoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<MemoriesInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMemoryByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessageEntityAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<MessageEntityAssociationsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessageQuoteAssociationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<MessageQuoteAssociationsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNewExternalMessagesArgs = {
  input: ExternalMessagesInput;
};


export type QueryOrderByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderDocumentsArgs = {
  orderId: Scalars['String']['input'];
};


export type QueryOrderSupportNotificationsArgs = {
  input: OrderSupportNotificationsInput;
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<OrdersInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationByIntegrationIdArgs = {
  input: OrganizationByIntegrationIdInput;
};


export type QueryOrganizationUserRelationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<OrganizationUserRelationsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<OrganizationsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOriginatorByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOriginatorEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<OriginatorEventsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOriginatorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<OriginatorsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPlaceByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<PlacesInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuoteByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuotesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<QuotesInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuotesStatsArgs = {
  input?: InputMaybe<QuotesInput>;
};


export type QueryRelationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySavedComputationByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySavedComputationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<SavedComputationsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<TemplatesInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplatesByOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryTemplatesByOriginatorArgs = {
  originatorId: Scalars['ID']['input'];
};


export type QueryTransformationConfigByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTransformationConfigsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<TransformationConfigsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTransformationDefinitionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTransformationDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<TransformationDefinitionsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<UsersInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkflowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input?: InputMaybe<WorkflowsInput>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkflowsByOriginatorArgs = {
  originatorId: Scalars['ID']['input'];
};

export type Quote = AbstractEntity & Node & {
  __typename?: 'Quote';
  carrierRate?: Maybe<CarrierRate>;
  carrierRates: Array<CarrierRate>;
  confidence: Scalars['Float']['output'];
  createdAt: Scalars['DateTimeISO']['output'];
  createdAtDisplay?: Maybe<Scalars['DateTimeISO']['output']>;
  customerRate?: Maybe<CustomerRate>;
  customerRates: Array<CustomerRate>;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalDeepLink?: Maybe<Scalars['String']['output']>;
  /** Serialized as JSON */
  extractedData?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  job: Job;
  notesCount: Scalars['Float']['output'];
  organization: Organization;
  originator?: Maybe<Originator>;
  primaryReference?: Maybe<Scalars['String']['output']>;
  quoteEvents: Array<QuoteEvent>;
  quoteNotes: Array<QuoteNote>;
  rateForm: RateForm;
  responseTime?: Maybe<Scalars['Float']['output']>;
  route?: Maybe<Scalars['JSON']['output']>;
  routeGeoCoordinates?: Maybe<Array<LatLng>>;
  status: QuoteBidStatus;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
  validation: QuoteValidationPayload;
};

export enum QuoteAutoReplyScope {
  DISABLED = 'DISABLED',
  DRAFT = 'DRAFT',
  SEND = 'SEND'
}

/** Customer facing status */
export enum QuoteBidStatus {
  LOST = 'LOST',
  NEW = 'NEW',
  QUOTED = 'QUOTED',
  WON = 'WON'
}

export type QuoteConnection = {
  __typename?: 'QuoteConnection';
  count: Scalars['Int']['output'];
  edges: Array<QuoteEdge>;
  nodes?: Maybe<Array<Quote>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QuoteDateFilter = {
  /** List Quote that have been created at or before the given date. */
  end?: InputMaybe<Scalars['DateTimeISO']['input']>;
  /** List Quote that have been created at or after the given date. */
  start?: InputMaybe<Scalars['DateTimeISO']['input']>;
};

export type QuoteEdge = {
  __typename?: 'QuoteEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Quote;
};

export type QuoteEvent = AbstractEntity & Node & {
  __typename?: 'QuoteEvent';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  /** Serialized as JSON */
  metadata?: Maybe<Scalars['JSON']['output']>;
  quoteId: Scalars['String']['output'];
  type: QuoteEventType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export enum QuoteEventType {
  AI_INFERENCE_COMPLETED = 'AI_INFERENCE_COMPLETED',
  AI_PROCESSING_COMPLETED = 'AI_PROCESSING_COMPLETED',
  AI_PROCESSING_FAILED = 'AI_PROCESSING_FAILED',
  AI_PROCESSING_STARTED = 'AI_PROCESSING_STARTED',
  AI_PROCESSING_TRIGGERED = 'AI_PROCESSING_TRIGGERED',
  CREATED_OPTIMISTICALLY = 'CREATED_OPTIMISTICALLY',
  EXTRACTED_DATA_EDITED = 'EXTRACTED_DATA_EDITED',
  MARKED_AS_LOST = 'MARKED_AS_LOST',
  MARKED_AS_NEW = 'MARKED_AS_NEW',
  MARKED_AS_NOT_A_QUOTE = 'MARKED_AS_NOT_A_QUOTE',
  MARKED_AS_QUOTED = 'MARKED_AS_QUOTED',
  MARKED_AS_WON = 'MARKED_AS_WON',
  ORIGINATOR_UPDATED = 'ORIGINATOR_UPDATED',
  PRICING_CHANGED = 'PRICING_CHANGED',
  RATING_FAILED = 'RATING_FAILED',
  RECEIVED_RATE = 'RECEIVED_RATE',
  REQUESTED_RATE = 'REQUESTED_RATE',
  USER_CONFIRMED = 'USER_CONFIRMED'
}

export type QuoteNote = AbstractEntity & Node & {
  __typename?: 'QuoteNote';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  quote: Quote;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user: User;
};

export type QuoteValidationPayload = {
  __typename?: 'QuoteValidationPayload';
  missingFields?: Maybe<Array<Scalars['String']['output']>>;
  valid: Scalars['Boolean']['output'];
  validationMessage?: Maybe<Scalars['String']['output']>;
};

export type QuotesInput = {
  confidenceRange?: InputMaybe<ConfidenceRange>;
  dateFilter?: InputMaybe<QuoteDateFilter>;
  equipmentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  freightModes?: InputMaybe<Array<Scalars['String']['input']>>;
  jobConfirmationFilterStatus?: InputMaybe<JobConfirmationFilterStatus>;
  laneFilter?: InputMaybe<LaneFilter>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outlookFilter?: InputMaybe<OutlookFilter>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<QuoteBidStatus>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QuotesStat = {
  __typename?: 'QuotesStat';
  count: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  totalCarrierRateValue: Scalars['Float']['output'];
  totalCustomerRateValue: Scalars['Float']['output'];
};

export enum RateComponentType {
  FUEL_SURCHARGE = 'FUEL_SURCHARGE',
  LINE_HAUL = 'LINE_HAUL',
  MANUAL_ADJUSTMENT = 'MANUAL_ADJUSTMENT',
  MARGIN = 'MARGIN',
  OTHER = 'OTHER',
  TOTAL = 'TOTAL'
}

export type RateForm = {
  __typename?: 'RateForm';
  carrierRateId?: Maybe<Scalars['String']['output']>;
  customerRateId?: Maybe<Scalars['String']['output']>;
  lineItems: Array<RateLineItem>;
};

export type RateLineItem = {
  __typename?: 'RateLineItem';
  buyRate: Scalars['Float']['output'];
  componentId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['Float']['output']>;
  sellRate: Scalars['Float']['output'];
  type: RateComponentType;
};

export type RatingError = {
  __typename?: 'RatingError';
  errorMessage?: Maybe<Scalars['String']['output']>;
  ratingMethod: RatingMethod;
};

/** The method used to rate a load */
export enum RatingMethod {
  ARCBEST_TL_QUOTING = 'ARCBEST_TL_QUOTING',
  CUSTOM = 'CUSTOM',
  DAT = 'DAT',
  GREENSCREENS = 'GREENSCREENS',
  HUMAN_INPUT = 'HUMAN_INPUT',
  NFI = 'NFI',
  SUNSET = 'SUNSET',
  TABI = 'TABI'
}

export type RedactOrganizationDataInput = {
  id: Scalars['ID']['input'];
  redactionDate: Scalars['DateTimeISO']['input'];
};

export type RedactOrganizationDataPayload = {
  __typename?: 'RedactOrganizationDataPayload';
  success: Scalars['Boolean']['output'];
};

export type RefreshOrderEntitiesInput = {
  id: Scalars['String']['input'];
};

export type RefreshOrderEntitiesPayload = {
  __typename?: 'RefreshOrderEntitiesPayload';
  success: Scalars['Boolean']['output'];
};

export type RemoveSenderFromOriginatorInput = {
  contactId: Scalars['String']['input'];
  originatorId: Scalars['String']['input'];
};

export type RemoveSenderFromOriginatorPayload = {
  __typename?: 'RemoveSenderFromOriginatorPayload';
  success: Scalars['Boolean']['output'];
};

export type RemoveUserFromOrganizationInput = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type RemoveUserFromOrganizationPayload = {
  __typename?: 'RemoveUserFromOrganizationPayload';
  success: Scalars['Boolean']['output'];
};

export type RepeatOrderInput = {
  id: Scalars['String']['input'];
  numRepeats: Scalars['Float']['input'];
};

export type RepeatOrderPayload = {
  __typename?: 'RepeatOrderPayload';
  orders: Array<Order>;
  success: Scalars['Boolean']['output'];
};

export type ReplicateOrderInput = {
  id: Scalars['String']['input'];
};

export type ReplicateOrderPayload = {
  __typename?: 'ReplicateOrderPayload';
  success: Scalars['Boolean']['output'];
};

export type Report = AbstractEntity & Node & {
  __typename?: 'Report';
  bucket?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  key?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  user?: Maybe<User>;
};

export type ReprocessOrderInput = {
  id: Scalars['String']['input'];
};

export type ReprocessOrderPayload = {
  __typename?: 'ReprocessOrderPayload';
  success: Scalars['Boolean']['output'];
};

/** The method used to make the api request */
export enum RequestMethod {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT'
}

export type SavedComputation = AbstractEntity & Node & {
  __typename?: 'SavedComputation';
  createdAt: Scalars['DateTimeISO']['output'];
  data?: Maybe<SavedComputationData>;
  dataUrl: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  externalMessageId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  job?: Maybe<Job>;
  jobId?: Maybe<Scalars['String']['output']>;
  labeledComputations: Array<LabeledComputation>;
  orderId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  originatorId?: Maybe<Scalars['String']['output']>;
  output?: Maybe<Scalars['JSON']['output']>;
  quoteId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SavedComputationType>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type SavedComputationConnection = {
  __typename?: 'SavedComputationConnection';
  count: Scalars['Int']['output'];
  edges: Array<SavedComputationEdge>;
  nodes?: Maybe<Array<SavedComputation>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type SavedComputationData = {
  __typename?: 'SavedComputationData';
  computationType: SavedComputationType;
  functionName: Scalars['String']['output'];
  inputs: Scalars['JSONObject']['output'];
  output?: Maybe<Scalars['JSON']['output']>;
  timestamp: Scalars['DateTimeISO']['output'];
};

export type SavedComputationEdge = {
  __typename?: 'SavedComputationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: SavedComputation;
};

export enum SavedComputationType {
  ADDRESS_PARSE = 'ADDRESS_PARSE',
  BUILD_ALL_QUOTES = 'BUILD_ALL_QUOTES',
  BUILD_ORDER = 'BUILD_ORDER',
  DECIDE_IGNORE_MESSAGE = 'DECIDE_IGNORE_MESSAGE',
  DECIDE_MESSAGE_ACTION = 'DECIDE_MESSAGE_ACTION',
  EMAIL_CLASSIFICATION = 'EMAIL_CLASSIFICATION',
  EMAIL_CLASSIFICATION_V2 = 'EMAIL_CLASSIFICATION_V2',
  ENTITY_DISCRIMINATION = 'ENTITY_DISCRIMINATION',
  INFER_MISSING_ADDRESS_COMPONENT = 'INFER_MISSING_ADDRESS_COMPONENT',
  LOCATION_MATCH = 'LOCATION_MATCH',
  ORIGINATOR_RESOLUTION = 'ORIGINATOR_RESOLUTION',
  RESOLVE_ORIGINATOR_AND_CONTACT = 'RESOLVE_ORIGINATOR_AND_CONTACT',
  TEMPLATE_COMPLETION = 'TEMPLATE_COMPLETION'
}

export type SavedComputationsInput = {
  jobIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  orderIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  quoteIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  types?: InputMaybe<Array<SavedComputationType>>;
};

export type SendOutlookMessageInput = {
  messageId: Scalars['String']['input'];
  sharedInbox?: InputMaybe<Scalars['String']['input']>;
};

export type SendOutlookMessagePayload = {
  __typename?: 'SendOutlookMessagePayload';
  success: Scalars['Boolean']['output'];
};

export type StopMarketConditions = {
  __typename?: 'StopMarketConditions';
  apiResponse: Scalars['JSON']['output'];
  requestType: CachedRequestType;
};

export enum SubmitOrderError {
  DOCUMENT_UPLOAD_FAILED = 'DOCUMENT_UPLOAD_FAILED'
}

export type SubmitOrderInput = {
  id: Scalars['String']['input'];
};

export type SubmitOrderPayload = {
  __typename?: 'SubmitOrderPayload';
  errors?: Maybe<Array<SubmitOrderError>>;
  order: Order;
  success: Scalars['Boolean']['output'];
};

export type SyncTemplateInput = {
  id: Scalars['ID']['input'];
};

export type SyncTemplatePayload = {
  __typename?: 'SyncTemplatePayload';
  destinationOrganization: Organization;
  destinationOriginator: Originator;
  destinationTemplate: Template;
  sourceOrganization: Organization;
  success: Scalars['Boolean']['output'];
};

export type Template = AbstractEntity & Node & {
  __typename?: 'Template';
  createdAt: Scalars['DateTimeISO']['output'];
  currentRevision: TemplateRevision;
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  modelConfig?: Maybe<ModelConfig>;
  multiOrderPrompt?: Maybe<Scalars['String']['output']>;
  orderBuildPrompt?: Maybe<Scalars['String']['output']>;
  revisions: Array<TemplateRevision>;
  /**
   * Serialized as JSON
   * @deprecated This field is now stored on the current revision entity.
   */
  specializedTemplate?: Maybe<Scalars['String']['output']>;
  type: TemplateType;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  count: Scalars['Int']['output'];
  edges: Array<TemplateEdge>;
  nodes?: Maybe<Array<Template>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum TemplateCreationFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Template;
};

export type TemplateRevision = AbstractEntity & Node & {
  __typename?: 'TemplateRevision';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  entity: Template;
  id: Scalars['ID']['output'];
  /** Serialized as JSON */
  jsonData?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export enum TemplateType {
  FREIGHT_ORDER = 'FREIGHT_ORDER',
  QUOTE = 'QUOTE'
}

export type TemplatesInput = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export enum TimeDisplayStyle {
  TIME_12H = 'TIME_12H',
  TIME_24H = 'TIME_24H'
}

export type TrackInput = {
  context: AnalyticsContext;
  event: Scalars['String']['input'];
  properties?: InputMaybe<TrackProperties>;
};

export type TrackProperties = {
  carrierRateId?: InputMaybe<Scalars['String']['input']>;
  count?: InputMaybe<Scalars['Float']['input']>;
  feedbackProperties?: InputMaybe<Scalars['JSON']['input']>;
  jobId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  originatorId?: InputMaybe<Scalars['String']['input']>;
  originatorName?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  quoteId?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['JSON']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TransformationConfig = AbstractEntity & Node & {
  __typename?: 'TransformationConfig';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  originator?: Maybe<Originator>;
  params: Scalars['JSON']['output'];
  transformationDefinition: TransformationDefinition;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  workflows: Array<WorkflowTransformation>;
};

export type TransformationConfigConnection = {
  __typename?: 'TransformationConfigConnection';
  count: Scalars['Int']['output'];
  edges: Array<TransformationConfigEdge>;
  nodes?: Maybe<Array<TransformationConfig>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TransformationConfigEdge = {
  __typename?: 'TransformationConfigEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: TransformationConfig;
};

export type TransformationConfigsInput = {
  organizationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TransformationDefinition = AbstractEntity & Node & {
  __typename?: 'TransformationDefinition';
  createdAt: Scalars['DateTimeISO']['output'];
  definition: Scalars['JSON']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  organization?: Maybe<Organization>;
  originator?: Maybe<Originator>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type TransformationDefinitionConnection = {
  __typename?: 'TransformationDefinitionConnection';
  count: Scalars['Int']['output'];
  edges: Array<TransformationDefinitionEdge>;
  nodes?: Maybe<Array<TransformationDefinition>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type TransformationDefinitionEdge = {
  __typename?: 'TransformationDefinitionEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: TransformationDefinition;
};

export type TransformationDefinitionsInput = {
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  originatorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TriggerExternalDataIngestInput = {
  dataType: IngestableExternalDataType;
  integrationType: IntegrationType;
  organizationId: Scalars['ID']['input'];
};

export type TriggerExternalDataIngestPayload = {
  __typename?: 'TriggerExternalDataIngestPayload';
  success: Scalars['Boolean']['output'];
};

export type TriggerPlaceImportInput = {
  format: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  s3Key: Scalars['String']['input'];
};

export type TriggerPlaceImportPayload = {
  __typename?: 'TriggerPlaceImportPayload';
  success: Scalars['Boolean']['output'];
};

export type TriggerPlaceIngestInput = {
  organizationId: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type TriggerPlaceIngestPayload = {
  __typename?: 'TriggerPlaceIngestPayload';
  success: Scalars['Boolean']['output'];
};

export type UpdateCarrierRateInput = {
  computedRate?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  loadData?: InputMaybe<Scalars['JSON']['input']>;
  ratingMethod?: InputMaybe<RatingMethod>;
};

export type UpdateCarrierRatePayload = {
  __typename?: 'UpdateCarrierRatePayload';
  carrierRate: CarrierRate;
  success: Scalars['Boolean']['output'];
};

export type UpdateContactInput = {
  id: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload';
  contact: Contact;
  success: Scalars['Boolean']['output'];
};

export type UpdateCustomerRateInput = {
  id: Scalars['ID']['input'];
  loadData?: InputMaybe<Scalars['JSON']['input']>;
  rate?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<CustomerRateType>;
};

export type UpdateCustomerRatePayload = {
  __typename?: 'UpdateCustomerRatePayload';
  customerRate: CustomerRate;
  success: Scalars['Boolean']['output'];
};

export type UpdateExternalMessageInput = {
  classifications?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  status?: InputMaybe<ClassificationStatus>;
  threadLength?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateExternalMessagePayload = {
  __typename?: 'UpdateExternalMessagePayload';
  externalMessage: ExternalMessage;
  success: Scalars['Boolean']['output'];
};

export type UpdateFeatureFlagInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizations?: InputMaybe<Scalars['JSON']['input']>;
  originators?: InputMaybe<Scalars['JSON']['input']>;
  users?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateJobInput = {
  confidence?: InputMaybe<Scalars['Float']['input']>;
  contactId?: InputMaybe<Scalars['ID']['input']>;
  emailMetadata?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateJobPayload = {
  __typename?: 'UpdateJobPayload';
  errors?: Maybe<Array<JobUpdateFailureReason>>;
  job: Job;
  success: Scalars['Boolean']['output'];
};

export type UpdateLabeledComputationInput = {
  id: Scalars['String']['input'];
  labeledComputationData: LabeledComputationDataInput;
};

export type UpdateMemberInput = {
  organizationUserRelationId: Scalars['ID']['input'];
  role?: InputMaybe<OrganizationUserRole>;
};

export type UpdateMemberPayload = {
  __typename?: 'UpdateMemberPayload';
  member: OrganizationUserRelation;
  success: Scalars['Boolean']['output'];
};

export enum UpdateMemoryFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type UpdateMemoryInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  labeledComputationId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateMemoryPayload = {
  __typename?: 'UpdateMemoryPayload';
  errors?: Maybe<Array<UpdateMemoryFailureReason>>;
  memory?: Maybe<Memory>;
  success: Scalars['Boolean']['output'];
};

export type UpdateOrderInput = {
  externalOrderId?: InputMaybe<Scalars['String']['input']>;
  extractedData?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  transmitId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload';
  errors?: Maybe<Array<OrderUpdateFailureReason>>;
  order: Order;
  success: Scalars['Boolean']['output'];
};

export type UpdateOrganizationInput = {
  emailAuthPolicy?: InputMaybe<EmailAuthPolicyInput>;
  emailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedWeeklyOrderCount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  integrationConfigs?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<Product>>;
  settings?: InputMaybe<OrganizationSettingsInput>;
};

export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  organization: Organization;
  success: Scalars['Boolean']['output'];
};

export type UpdateOriginatorInput = {
  autoSubmitScope?: InputMaybe<AutoSubmitScope>;
  configOverrides?: InputMaybe<Scalars['JSON']['input']>;
  expectedWeeklyOrderCount?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orderNotesConfig?: InputMaybe<NotesFieldConfigInput>;
  quoteAutoReplyScope?: InputMaybe<QuoteAutoReplyScope>;
  status?: InputMaybe<OriginatorStatus>;
  transmitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  voomaSupportNotes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOriginatorPayload = {
  __typename?: 'UpdateOriginatorPayload';
  originator: Originator;
  success: Scalars['Boolean']['output'];
};

export type UpdatePlaceInput = {
  address?: InputMaybe<PlaceAddressInput>;
  aliases?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  locationCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notesConfig?: InputMaybe<NotesFieldConfigInput>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<PlaceTag>>;
};

export type UpdatePlacePayload = {
  __typename?: 'UpdatePlacePayload';
  place: Place;
  success: Scalars['Boolean']['output'];
};

export type UpdateQuoteInput = {
  carrierRateId?: InputMaybe<Scalars['ID']['input']>;
  confidence?: InputMaybe<Scalars['Float']['input']>;
  customerRateId?: InputMaybe<Scalars['ID']['input']>;
  extractedData?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateQuotePayload = {
  __typename?: 'UpdateQuotePayload';
  quote: Quote;
  success: Scalars['Boolean']['output'];
};

export type UpdateTemplateInput = {
  id: Scalars['ID']['input'];
  modelConfig?: InputMaybe<ModelConfigInput>;
  multiOrderPrompt?: InputMaybe<Scalars['String']['input']>;
  orderBuildPrompt?: InputMaybe<Scalars['String']['input']>;
  specializedTemplate?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TemplateType>;
};

export type UpdateTemplatePayload = {
  __typename?: 'UpdateTemplatePayload';
  success: Scalars['Boolean']['output'];
  template: Template;
};

export type UpdateTemplateUsingOrderInput = {
  id: Scalars['String']['input'];
};

export type UpdateTemplateUsingOrderPayload = {
  __typename?: 'UpdateTemplateUsingOrderPayload';
  success: Scalars['Boolean']['output'];
};

export enum UpdateTransformationConfigFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type UpdateTransformationConfigInput = {
  id: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['String']['input']>;
  params?: InputMaybe<Scalars['JSON']['input']>;
  transformationDefinitionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTransformationConfigPayload = {
  __typename?: 'UpdateTransformationConfigPayload';
  errors?: Maybe<Array<UpdateTransformationConfigFailureReason>>;
  success: Scalars['Boolean']['output'];
  transformationConfig?: Maybe<TransformationConfig>;
};

export enum UpdateTransformationDefinitionFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type UpdateTransformationDefinitionInput = {
  definition?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['String']['input']>;
  transformationDefinitionId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTransformationDefinitionPayload = {
  __typename?: 'UpdateTransformationDefinitionPayload';
  errors?: Maybe<Array<UpdateTransformationDefinitionFailureReason>>;
  success: Scalars['Boolean']['output'];
  transformationDefinition?: Maybe<TransformationDefinition>;
};

export type UpdateUserFeaturesInput = {
  features?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  features?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  success: Scalars['Boolean']['output'];
  user: User;
};

export type UpdateUserSettingsInput = {
  id: Scalars['ID']['input'];
  settings?: InputMaybe<UserSettingsInput>;
};

export enum UpdateWorkflowFailureReason {
  UNKNOWN = 'UNKNOWN'
}

export type UpdateWorkflowInput = {
  id: Scalars['ID']['input'];
  originatorId?: InputMaybe<Scalars['String']['input']>;
  transformationConfigIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  trigger?: InputMaybe<FlowTriggerInput>;
};

export type UpdateWorkflowPayload = {
  __typename?: 'UpdateWorkflowPayload';
  errors?: Maybe<Array<UpdateWorkflowFailureReason>>;
  success: Scalars['Boolean']['output'];
  workflow?: Maybe<Workflow>;
};

/** File upload metadata. */
export type UploadFileInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  subjectId?: InputMaybe<Scalars['ID']['input']>;
  subjectType: UploadableEntity;
};

/** Return type for the uploadFile mutation */
export type UploadFilePayload = {
  __typename?: 'UploadFilePayload';
  subject?: Maybe<AbstractEntity>;
  success: Scalars['Boolean']['output'];
  upload?: Maybe<FileUpload>;
  url: Scalars['String']['output'];
};

export enum UploadableEntity {
  JOB_DOCUMENT_UPLOAD_ADD_TO_EXISTING_JOB = 'JOB_DOCUMENT_UPLOAD_ADD_TO_EXISTING_JOB',
  JOB_DOCUMENT_UPLOAD_PRIOR_TO_JOB_CREATION = 'JOB_DOCUMENT_UPLOAD_PRIOR_TO_JOB_CREATION',
  LOCATION_IMPORT = 'LOCATION_IMPORT',
  PROFILE_PHOTO_UPLOAD = 'PROFILE_PHOTO_UPLOAD'
}

/** The usage indicator for the integration. */
export enum UsageIndicator {
  PRODUCTION = 'PRODUCTION',
  TEST = 'TEST'
}

export type User = AbstractEntity & BasicUser & Node & {
  __typename?: 'User';
  createdAt: Scalars['DateTimeISO']['output'];
  debug: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  /** This user's email if available. */
  email?: Maybe<Scalars['String']['output']>;
  /** Serialized as JSON */
  features?: Maybe<Scalars['JSON']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intercomUserHash: Scalars['String']['output'];
  isVerified: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  organizations: Array<OrganizationUserRelation>;
  organizationsDetails: Array<Organization>;
  photo?: Maybe<UserProfilePhoto>;
  profile?: Maybe<UserProfile>;
  roles: Array<UserRole>;
  settings?: Maybe<UserSettings>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

/** Connection type for users. */
export type UserConnection = {
  __typename?: 'UserConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserEdge>;
  nodes?: Maybe<Array<User>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  name: Scalars['String']['output'];
};

export type UserProfilePhoto = AbstractEntity & FileUpload & Node & {
  __typename?: 'UserProfilePhoto';
  author: User;
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  url: Scalars['String']['output'];
};

export enum UserRole {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  OPERATIONS = 'OPERATIONS',
  SUPPORT = 'SUPPORT',
  SYSTEM = 'SYSTEM'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  autoResponseInstructions?: Maybe<Scalars['String']['output']>;
  timeDisplayStyle?: Maybe<TimeDisplayStyle>;
};

export type UserSettingsInput = {
  autoResponseInstructions?: InputMaybe<Scalars['String']['input']>;
  timeDisplayStyle?: InputMaybe<TimeDisplayStyle>;
};

export type UsersInput = {
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

/** The API client registering tracking calls. */
export enum VoomaApiClient {
  CHROME = 'CHROME',
  FRONT = 'FRONT',
  OUTLOOK = 'OUTLOOK',
  WEB = 'WEB'
}

export type Workflow = AbstractEntity & Node & {
  __typename?: 'Workflow';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  organization: Organization;
  originator?: Maybe<Originator>;
  transformations: Array<WorkflowTransformation>;
  trigger: FlowTrigger;
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
};

export type WorkflowConnection = {
  __typename?: 'WorkflowConnection';
  count: Scalars['Int']['output'];
  edges: Array<WorkflowEdge>;
  nodes?: Maybe<Array<Workflow>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type WorkflowEdge = {
  __typename?: 'WorkflowEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Workflow;
};

export type WorkflowTransformation = AbstractEntity & Node & {
  __typename?: 'WorkflowTransformation';
  createdAt: Scalars['DateTimeISO']['output'];
  deletedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  id: Scalars['ID']['output'];
  seq: Scalars['Int']['output'];
  transformationConfig: TransformationConfig;
  transformationConfigId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTimeISO']['output']>;
  workflow: Workflow;
  workflowId: Scalars['String']['output'];
};

export type WorkflowsInput = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  originatorId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFrontReplyAllMutationVariables = Exact<{
  input: CreateFrontReplyAllInput;
}>;


export type CreateFrontReplyAllMutation = { __typename?: 'Mutation', createFrontReplyAll: { __typename?: 'CreateFrontReplyAllPayload', success: boolean, messageId?: string | null } };

export type CreateJobFromFrontMutationVariables = Exact<{
  input: CreateFrontJobInput;
}>;


export type CreateJobFromFrontMutation = { __typename?: 'Mutation', createFrontJob: { __typename?: 'CreateJobPayload', success: boolean, job: { __typename?: 'Job', id: string, createdAt: any, notes?: string | null, emailMetadata?: any | null, status: ProcessingStatus, source: JobSource, originator?: { __typename?: 'Originator', name: string } | null, orders: Array<{ __typename?: 'Order', id: string, extractedData?: string | null, createdAt: any, originator?: { __typename?: 'Originator', id: string, name: string } | null, job: { __typename?: 'Job', emailMetadata?: any | null }, orderEvents: Array<{ __typename?: 'OrderEvent', id: string, createdAt: any, type: OrderEventType, orderId: string }> }>, quotes: Array<{ __typename?: 'Quote', id: string, status: QuoteBidStatus, extractedData?: any | null, createdAt: any, originator?: { __typename?: 'Originator', name: string } | null, job: { __typename?: 'Job', id: string, emailMetadata?: any | null, source: JobSource }, customerRate?: { __typename?: 'CustomerRate', id: string, createdAt: any, type: CustomerRateType, rate?: any | null, metadata?: any | null } | null }>, jobEvents: Array<{ __typename?: 'JobEvent', id: string, createdAt: any, type: JobEventType }> } } };

export type JobEventFragment = { __typename?: 'JobEvent', id: string, createdAt: any, type: JobEventType };

export type JobSummaryFragment = { __typename?: 'Job', id: string, createdAt: any, notes?: string | null, emailMetadata?: any | null, status: ProcessingStatus, source: JobSource, originator?: { __typename?: 'Originator', name: string } | null, orders: Array<{ __typename?: 'Order', id: string, extractedData?: string | null, createdAt: any, originator?: { __typename?: 'Originator', id: string, name: string } | null, job: { __typename?: 'Job', emailMetadata?: any | null }, orderEvents: Array<{ __typename?: 'OrderEvent', id: string, createdAt: any, type: OrderEventType, orderId: string }> }>, quotes: Array<{ __typename?: 'Quote', id: string, status: QuoteBidStatus, extractedData?: any | null, createdAt: any, originator?: { __typename?: 'Originator', name: string } | null, job: { __typename?: 'Job', id: string, emailMetadata?: any | null, source: JobSource }, customerRate?: { __typename?: 'CustomerRate', id: string, createdAt: any, type: CustomerRateType, rate?: any | null, metadata?: any | null } | null }>, jobEvents: Array<{ __typename?: 'JobEvent', id: string, createdAt: any, type: JobEventType }> };

export type OrderEventFragment = { __typename?: 'OrderEvent', id: string, createdAt: any, type: OrderEventType, orderId: string };

export type OrderSummaryFragment = { __typename?: 'Order', id: string, extractedData?: string | null, createdAt: any, originator?: { __typename?: 'Originator', id: string, name: string } | null, job: { __typename?: 'Job', emailMetadata?: any | null }, orderEvents: Array<{ __typename?: 'OrderEvent', id: string, createdAt: any, type: OrderEventType, orderId: string }> };

export type QuoteEventFragment = { __typename?: 'QuoteEvent', id: string, createdAt: any, type: QuoteEventType, quoteId: string };

export type QuoteSummaryFragment = { __typename?: 'Quote', id: string, status: QuoteBidStatus, extractedData?: any | null, createdAt: any, originator?: { __typename?: 'Originator', name: string } | null, job: { __typename?: 'Job', id: string, emailMetadata?: any | null, source: JobSource }, customerRate?: { __typename?: 'CustomerRate', id: string, createdAt: any, type: CustomerRateType, rate?: any | null, metadata?: any | null } | null };

export const OrderEventFragmentDoc = gql`
    fragment OrderEvent on OrderEvent {
  id
  createdAt
  type
  orderId
}
    `;
export const OrderSummaryFragmentDoc = gql`
    fragment OrderSummary on Order {
  id
  extractedData
  createdAt
  originator {
    id
    name
  }
  job {
    emailMetadata
  }
  orderEvents {
    ...OrderEvent
  }
}
    ${OrderEventFragmentDoc}`;
export const QuoteSummaryFragmentDoc = gql`
    fragment QuoteSummary on Quote {
  id
  status
  extractedData
  createdAt
  originator {
    name
  }
  job {
    id
    emailMetadata
    source
  }
  customerRate {
    id
    createdAt
    type
    rate
    metadata
  }
}
    `;
export const JobEventFragmentDoc = gql`
    fragment JobEvent on JobEvent {
  id
  createdAt
  type
}
    `;
export const JobSummaryFragmentDoc = gql`
    fragment JobSummary on Job {
  id
  createdAt
  notes
  emailMetadata
  status
  source
  originator {
    name
  }
  orders {
    ...OrderSummary
  }
  quotes {
    ...QuoteSummary
  }
  jobEvents {
    ...JobEvent
  }
}
    ${OrderSummaryFragmentDoc}
${QuoteSummaryFragmentDoc}
${JobEventFragmentDoc}`;
export const QuoteEventFragmentDoc = gql`
    fragment QuoteEvent on QuoteEvent {
  id
  createdAt
  type
  quoteId
}
    `;
export const CreateFrontReplyAllDocument = gql`
    mutation CreateFrontReplyAll($input: CreateFrontReplyAllInput!) {
  createFrontReplyAll(input: $input) {
    success
    messageId
  }
}
    `;
export type CreateFrontReplyAllMutationFn = Apollo.MutationFunction<CreateFrontReplyAllMutation, CreateFrontReplyAllMutationVariables>;

/**
 * __useCreateFrontReplyAllMutation__
 *
 * To run a mutation, you first call `useCreateFrontReplyAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFrontReplyAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFrontReplyAllMutation, { data, loading, error }] = useCreateFrontReplyAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFrontReplyAllMutation(baseOptions?: Apollo.MutationHookOptions<CreateFrontReplyAllMutation, CreateFrontReplyAllMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFrontReplyAllMutation, CreateFrontReplyAllMutationVariables>(CreateFrontReplyAllDocument, options);
      }
export type CreateFrontReplyAllMutationHookResult = ReturnType<typeof useCreateFrontReplyAllMutation>;
export type CreateFrontReplyAllMutationResult = Apollo.MutationResult<CreateFrontReplyAllMutation>;
export type CreateFrontReplyAllMutationOptions = Apollo.BaseMutationOptions<CreateFrontReplyAllMutation, CreateFrontReplyAllMutationVariables>;
export const CreateJobFromFrontDocument = gql`
    mutation CreateJobFromFront($input: CreateFrontJobInput!) {
  createFrontJob(input: $input) {
    success
    job {
      ...JobSummary
    }
  }
}
    ${JobSummaryFragmentDoc}`;
export type CreateJobFromFrontMutationFn = Apollo.MutationFunction<CreateJobFromFrontMutation, CreateJobFromFrontMutationVariables>;

/**
 * __useCreateJobFromFrontMutation__
 *
 * To run a mutation, you first call `useCreateJobFromFrontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobFromFrontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobFromFrontMutation, { data, loading, error }] = useCreateJobFromFrontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobFromFrontMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobFromFrontMutation, CreateJobFromFrontMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobFromFrontMutation, CreateJobFromFrontMutationVariables>(CreateJobFromFrontDocument, options);
      }
export type CreateJobFromFrontMutationHookResult = ReturnType<typeof useCreateJobFromFrontMutation>;
export type CreateJobFromFrontMutationResult = Apollo.MutationResult<CreateJobFromFrontMutation>;
export type CreateJobFromFrontMutationOptions = Apollo.BaseMutationOptions<CreateJobFromFrontMutation, CreateJobFromFrontMutationVariables>;